import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import AuthContext from "../../context/AuthContext";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { AiOutlineClose } from "react-icons/ai";
import config from "../../config";
import AddCourseFaq from "./AddCourseFaq";


const EditCourseForm = ({ courseId }) => {
  const { user, authTokens } = useContext(AuthContext);
  const token = authTokens?.access;
  // const { courseId } = useParams(); // Get course ID from URL

  const [courseData, setCourseData] = useState(null);
  const [categories, setCategories] = useState([]);
  const [alert, setAlert] = useState({ type: "", message: "", show: false });

  const showAlert = (type, message) => {
    toast.success(message, {
      position: 'top-center',
    });
    setAlert({ type, message, show: true });
    setTimeout(() => setAlert({ type: "", message: "", show: false }), 3000);
  };

  //  Fetch Course Data & Categories
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [courseResponse, categoriesResponse] = await Promise.all([
          axios.get(`${config.apiUrl}course/${courseId}/`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${config.apiUrl}courseCategory/`),
        ]);
        setCourseData(courseResponse.data);
        setCategories(categoriesResponse.data);
      } catch (error) {
        console.error("❌ Error fetching data:", error);
        showAlert("error", "❌ Failed to load course data.");
      }
    };
    fetchData();
  }, [courseId, token]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setCourseData((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      console.log("File:", files[0]);
      setCourseData((prev) => ({ ...prev, [name]: files[0] }));
    }
  };

  const handleCategoryChange = (e) => {
    const selectedCategoryId = parseInt(e.target.value, 10);
    setCourseData((prevData) => {
      const updatedCategories = prevData.courseCategory.includes(selectedCategoryId)
        ? prevData.courseCategory.filter((id) => id !== selectedCategoryId)
        : [...prevData.courseCategory, selectedCategoryId];
      return { ...prevData, courseCategory: updatedCategories };
    });
  };

  const handleModuleChange = (e, moduleId) => {
    const { name, value } = e.target;
    setCourseData((prev) => ({
      ...prev,
      modules: prev.modules.map((mod) =>
        mod.moduleId === moduleId ? { ...mod, [name]: value } : mod
      ),
    }));
  };

  const handleContentChange = (e, moduleId, contentId) => {
    const { name, value, files } = e.target;
    setCourseData((prev) => ({
      ...prev,
      modules: prev.modules.map((mod) =>
        mod.moduleId === moduleId
          ? {
            ...mod,
            contents: mod.contents.map((content) =>
              content.contentId === contentId
                ? { ...content, [name]: files?.length > 0 ? files[0] : value }
                : content
            ),
          }
          : mod
      ),
    }));
  };

  const handleSaveModule = async (module) => {
    try {
      if (module.isNew) {
        const response = await axios.post(`${config.apiUrl}module/`, module, {
          headers: { Authorization: `Bearer ${token}` },
        });
        showAlert("success", " Module added successfully!");
        setCourseData((prev) => ({
          ...prev,
          modules: prev.modules.map((mod) =>
            mod.moduleId === module.moduleId ? { ...mod, moduleId: response.data.moduleId, isNew: false } : mod
          ),
        }));
      } else {
        await axios.put(`${config.apiUrl}module/${module.moduleId}/`, module, {
          headers: { Authorization: `Bearer ${token}` },
        });
        showAlert("success", " Module updated successfully!");
      }
    } catch (error) {
      console.error("❌ Error saving module:", error.response?.data);
      showAlert("error", "❌ Failed to save module.");
    }
  };

  const handleSaveContent = async (content) => {
    try {
      const formData = new FormData();
      Object.entries(content).forEach(([key, value]) => {
        // formData.append(key, value);
        if (value !== null) formData.append(key, value);
      });

      if (content.isNew) {
        const response = await axios.post(`${config.apiUrl}contentapi/`, formData, {
          headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
        });
        showAlert("success", " Content added successfully!");
        setCourseData((prev) => ({
          ...prev,
          modules: prev.modules.map((mod) =>
            mod.moduleId === content.moduleId
              ? {
                ...mod,
                contents: mod.contents.map((cont) =>
                  cont.contentId === content.contentId ? { ...cont, contentId: response.data.contentId, isNew: false } : cont
                ),
              }
              : mod
          ),
        }));
      } else {
        await axios.put(`${config.apiUrl}contentapi/${content.contentId}/`, formData, {
          headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
        });
        showAlert("success", " Content updated successfully!");


      }
    } catch (error) {
      console.error("❌ Error saving content:", error.response?.data);
      showAlert("error", "❌ Failed to save content.");
    }
  };

  const handleAddContent = (module) => {
    console.log("Module:", module);
    const newContent = {
      contentId: Date.now(),
      title: "",
      contentPosition: (module.contents.length || 0) + 1,
      moduleId: module.moduleId,
      contentType: 2,
      file: null,
      videoLink: "",
      isNew: true,
    };
    console.log("New Content:", newContent);
    setCourseData((prev) => ({
      ...prev,
      modules: prev.modules.map((mod) =>
        mod.moduleId === module.moduleId
          ? { ...mod, contents: [...mod.contents, newContent] }
          : mod
      ),
    }));
  };

  const handleAddModule = () => {
    const newModule = {
      moduleId: Date.now(),
      moduleTitle: "",
      modulePosition: courseData.modules?.length + 1,
      courseId,
      contents: [],
      isNew: true,
    };

    setCourseData((prev) => ({
      ...prev,
      modules: [...prev.modules, newModule],
    }));
  };

  // console.log("Course Data:", courseData);

  const handleDeleteContent = async (moduleId, contentId) => {
    if (courseData.modules.find((mod) => mod.moduleId === moduleId)?.contents.find((content) => content.contentId === contentId)?.isNew) {

      setCourseData((prev) => ({
        ...prev,
        modules: prev.modules.map((mod) =>
          mod.moduleId === moduleId
            ? { ...mod, contents: mod.contents.filter((content) => content.contentId !== contentId) }
            : mod
        ),
      }));
      return;
    }
    const confirmDelete = window.confirm("Are you sure you want to delete this content?");
    if (!confirmDelete) return;

    try {
      await axios.delete(`${config.apiUrl}contentapi/${contentId}/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCourseData((prev) => ({
        ...prev,
        modules: prev.modules.map((mod) =>
          mod.moduleId === moduleId
            ? { ...mod, contents: mod.contents.filter((content) => content.contentId !== contentId) }
            : mod
        ),
      }));
      showAlert('success', ' Content deleted successfully!');
    } catch (error) {
      console.error("❌ Error deleting content:", error.response?.data);
      showAlert('error', '❌ Failed to delete content.');
    }
  };


  const handleDeleteModule = async (moduleId) => {
    if (courseData.modules.find((mod) => mod.moduleId === moduleId)?.isNew) {
      setCourseData((prev) => ({
        ...prev,
        modules: prev.modules.filter((mod) => mod.moduleId !== moduleId),
      })); return;
    }
    const confirmDelete = window.confirm("Are you sure you want to delete this module?");
    if (!confirmDelete) return;

    try {
      await axios.delete(`${config.apiUrl}module/${moduleId}/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // setModuleData((prev) => prev.filter((mod) => mod.id !== moduleId));
      setCourseData((prev) => ({
        ...prev,
        modules: prev.modules.filter((mod) => mod.moduleId !== moduleId),
      }));
      showAlert('success', ' Module deleted successfully!');
    } catch (error) {
      console.error("❌ Error deleting module:", error.response?.data);
      showAlert('error', '❌ Failed to delete module.');
    }
  };

  const handleSaveCourse = async () => {
    try {
      const formData = new FormData();
      Object.entries(courseData).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((v) => formData.append(key, v));
        } else {
          if (value !== null) {
            // console.log("Key:", key, "Value:", value);
            formData.append(key, value);
          }
        }
      });

      await axios.put(`${config.apiUrl}upload-course/${courseId}/`, formData, {
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
      });

      showAlert("success", " Course updated successfully!");
    } catch (error) {
      console.error("❌ Error updating course:", error.response?.data);
      showAlert("error", "❌ Failed to update course.");
    }
  };


  if (!courseData) return <p>Loading...</p>;

  return (
    <div className="flex flex-col lg:flex-row min-h-screen bg-gray-100 p-6 gap-6">
      <div className="w-full lg:w-1/2 bg-white p-6 rounded-xl shadow-md">
        <h2 className="text-2xl font-bold">✏️ Edit Course Info</h2>
        {/* {alert.show && (
          <Alert severity={alert.type} style={{ marginBottom: "1rem" }}>
            {alert.message}
          </Alert>
        )} */}

        <input
          type="text"
          name="courseTitle"
          value={courseData.courseTitle}
          onChange={handleInputChange}
          placeholder="Course Title"
          className="w-full p-2 border rounded mb-2"
        />

        <input type="file" name="bannerImg" onChange={handleFileChange} className="mb-2" />
        <input
          type="text"
          name="topicList"
          value={courseData.topicList}
          onChange={handleInputChange}
          placeholder="Course TopicList"
          className="w-full p-2 border rounded mb-2"
        />
        {courseData.bannerImg && <img src={`${courseData.bannerImg}`} width={200} height={200} />}

        <label>Course Details </label>
        <textarea
          name="courseDetails"
          value={courseData.courseDetails}
          onChange={handleInputChange}
          placeholder="Course Details"
          className="w-full p-2 border rounded mb-2"
        ></textarea>

        <label>Course Type</label>
        <select
          name="courseType"
          value={courseData.courseType}
          onChange={handleInputChange}
          className="w-full p-2 border rounded mb-2"
        >
          <option value="0">Free</option>
          <option value="1">Paid</option>
          <option value="2">Scholarship</option>
        </select>

        <label>Course Fee</label>
        <input
          type="number"
          name="courseFee"
          value={courseData.courseFee}
          onChange={handleInputChange}
          className="w-full p-2 border rounded mb-2"
        />

        <label>Course Discount</label>
        <input
          type="number"
          name="courseDiscount"
          value={courseData.courseDiscount}
          onChange={(e) => {
            const value = e.target.value;
            if (value >= 0 || value === "") {
              handleInputChange(e);
            }
          }}
          className="w-full p-2 border rounded mb-2"
        />

        <label className="block font-semibold">Course Category</label>
        <div className="flex flex-wrap gap-4 mb-4">
          {categories.map((category) => (
            <div key={category.id} className="flex items-center gap-2">
              <input
                type="checkbox"
                id={category.id}
                value={category.id}
                checked={courseData.courseCategory.includes(category.id)}
                onChange={handleCategoryChange}
                className="w-4 h-4"
              />
              <label htmlFor={category.id}>{category.name}</label>
            </div>
          ))}
        </div>

        <Button variant="contained" color="primary" onClick={handleSaveCourse}>
          💾 Update Course
        </Button>
        <AddCourseFaq courseId={courseId} /> 
      </div>
      <div className="w-full lg:w-1/2 bg-white p-6 rounded-xl shadow-md">
        <h2 className="text-2xl font-bold">📦 Edit Modules & Contents</h2>

        {courseData.modules.map((module) => (
          <div key={module.moduleId} className="border p-4 rounded-md shadow-sm mb-4 bg-amber-100">
            <p className="text-2xl"> Module -{module?.modulePosition}</p>

            <div className="flex justify-end items-start ">
              <Button variant="outlined" onClick={() => handleDeleteModule(module.moduleId || module.id)} color="error">
                <AiOutlineClose />
              </Button>
            </div>
            <input
              type="text"
              name="moduleTitle"
              value={module.moduleTitle}
              onChange={(e) => handleModuleChange(e, module.moduleId)}
              placeholder="Module Title"
              className="w-full p-2 border rounded mb-2"
            />

            <Button
              variant="contained"
              color="success"
              onClick={() => handleSaveModule(module)}
            >
              💾 Save Module
            </Button>

            {module.contents.map((content) => (
              <div key={content.contentId} className="p-3 mt-2 border rounded bg-gray-50 text-black">
                <p className="text-xl"> Module -{module?.modulePosition} : Content - {content?.contentPosition}</p>

                <div className="flex justify-end items-start ">
                  <Button variant="contained" onClick={() => handleDeleteContent(module.moduleId || module.id, content.contentId || content.id)} color="warning">
                    <AiOutlineClose />
                  </Button>
                </div>
                <input
                  type="text"
                  name="title"
                  value={content.title}
                  onChange={(e) => handleContentChange(e, module.moduleId, content.contentId)}
                  placeholder="Content Title"
                  className="w-full p-2 border rounded mb-2"
                />

                <select
                  name="contentType"
                  value={content.contentType}
                  onChange={(e) => handleContentChange(e, module.moduleId, content.contentId)}
                  className="w-full p-2 border rounded mb-2"
                >
                  <option value="1">Video</option>
                  <option value="2">Video Link</option>
                  <option value="3">PDF</option>
                </select>

                {content.contentType === "1" || content.contentType === "3" ? (
                  <input
                    type="file"
                    name="file"
                    onChange={(e) => handleContentChange(e, module.moduleId, content.contentId)}
                    className="w-full p-2"
                  />
                ) : (
                  <input
                    type="url"
                    name="videoLink"
                    value={content.videoLink}
                    onChange={(e) => handleContentChange(e, module.moduleId, content.contentId)}
                    placeholder="Video Link"
                    className="w-full p-2 border rounded"
                  />
                )}

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleSaveContent(content)}
                >
                  💾 Save Content
                </Button>

              </div>
            ))}
            <Button
              variant="contained"

              color="info"
              textColor="warning"
              onClick={() => handleAddContent(module)}

              className="w-full mt-2"
            >
              ➕ Add New Content
            </Button>
          </div>

        ))}
        <Button variant="contained" color="primary" onClick={handleAddModule} >
          ➕ Add New Module
        </Button>
      </div>
      
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default EditCourseForm;
