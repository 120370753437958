import Navbar from "../../components/Navbar";
import Hero from "../../components/Hero";
import MiddleComponent from "../MiddleComponent";
import Footer from "../../components/MyFooter";
import './HomeCss/Home.css'
import { Box, Button, Grid, Typography } from "@mui/material";
//import { Card } from "react-bootstrap";
import HomeCard1 from "./HomeCard1";
import HomeCard2 from "./HomeCard2";
import HomeCard3 from "./HomeCard3";
import HomeCard4 from "./HomeCard4";
// import { AcademicOptions } from "./AcademicOptions";
// import { Link } from "react-router-dom";
import { useState ,useContext, useEffect} from "react";
import AcademicHoverCard from "./AcademicHoverCard";
import IntroductionVideo from '../../assets/Videos/THE SEED.mp4'
import HomeCourseCard from "./HomeCourseCard";
import AuthContext from "../../context/AuthContext";
import axios from "axios";
import config from "../../config";
import CourseCard from "../Course/CourseCard";
import SliderComponent from "./SliderComponent";
import CourseTabs from "./CourseTabs";
import LMSStats from "./LMSStats";
import Testimonials from "./Testimonials";
import NewsletterSubscription from "./NewsletterSubscription";
import LowerTestimonial from "./LowerTestimonial";
import FloatingButton from "./FloatingButton";
import FloatingButtons from "./FloatingButtons";

function Home(){
    //const [dropdownAcademic, setDropdownAcademic] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { user,authTokens,userInfo } = useContext(AuthContext);
    const token = authTokens?.access;
    const [filteredCourses, setFilteredCourses] = useState([]);


    const handleMouseOver = () => {
        setIsHovering(true);
      };
    
      const handleMouseOut = () => {
        setIsHovering(false);
      };

      useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}courseapi`);
                setCourses(response.data);
                setFilteredCourses(response.data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchCourses();
    }, []);

    return(
        <div> 
        <Navbar/> 
        
        {/* <Hero/>  */}

        {/* 🚀 Hero Section */}
        <div className="home-hero-section">
            <div className="home-hero-overlay"></div> {/* Background Effect */}
            <div className="home-hero-content">
                <h1 className="home-hero-title">
                    Empower <span>Your Learning</span> Journey 🚀
                </h1>
                <p className="home-hero-subtext">
                    Unlock your potential with world-class courses & mentorship.
                </p>
                <Button variant="contained" className="home-hero-btn" onClick={() => window.location.href = '/freecourse'}>
                    Get Started for Free
                </Button>
            </div>
        </div>

        {/* 📢 Announcement / Notices */}
        <div className="home-hero-announcement-bar">
            <marquee className="home-hero-announcement-text">
                📣 <strong>Special Offer:</strong> Enroll now & get <span>50% OFF</span> on all courses this month! 🎉
            </marquee>
        </div>



        {/* <MiddleComponent /> */}
        
        <SliderComponent/>

        {/* <div className="home-courses-text">
            All Courses
            <input
            placeholder="Search"
            />
        </div> */}
        {/* <Grid container my={4} spacing={2} className="home-grid">
            <Grid item xs sm md>
            <HomeCard1
            handleMouseOver={handleMouseOver}
            handleMouseOut={handleMouseOut}
            />
             {AcademicOptions.map((item,index) => {
                    <Grid key={index}
                    onMouseEnter={()=> setDropdownAcademic(true)}
                    onMouseLeave={()=> setDropdownAcademic(false)}
                    >
                    <Link className={item.cName} to={item.url}>
                        <i className={item.icon}></i>{item.title}
                    </Link>
                        
                    </Grid>
                })} 
                {isHovering && <AcademicHoverCard/>}
            </Grid>
            <Grid item xs sm md>
            <HomeCard2/>
            </Grid>
            <Grid item xs sm md>
            <HomeCard3/>
            </Grid>
            <Grid item xs sm md>
            <HomeCard4/>
            </Grid>
            
            
        </Grid>// */}

        <CourseTabs/>

        <div className="home-trending-courses">Trending Courses</div>
        <Grid container className='home-trending-courses-box'>
            {/* <HomeCourseCard/>
            <HomeCourseCard/>
            <HomeCourseCard/>
            <HomeCourseCard/>
            <HomeCourseCard/>
            <HomeCourseCard/>
            <HomeCourseCard/> */}
            {filteredCourses.map(course => (
                    <CourseCard course={course} userInfo ={userInfo}/>
            ))}
        </Grid>

        <LMSStats/>

        <div width='100%' alignItems='center' className="home-free-live-video">
            <div>
            {/* <CardMedia
                component="video"
                image={IntroductionVideo}
                title='An Introduction Video.'
                autoPlay
                className="home-free-live-video-video"
                
            /> */}
            <video className='home-free-live-video-video' controls autoPlay loop muted>
                <source src={IntroductionVideo} type='video/mp4'/>
            </video>
            </div>
            
            <div>
                <div className="home-free-live-video-text1">
                    Join Our Free Live Class
                </div> 
                <Typography gutterBottom variant="h6" color='text.secondary' className="home-free-live-video-text2">
                Education is an important part of human life. Education gives meaning to our life as it enables the growth and development of our mind and intellect. An educated society is an enlightened and empowered one. Such a society can make well-informed choices in its social, political and economic welfare.
                </Typography>
            </div>
        </div>

        <Testimonials/>

        <NewsletterSubscription/>

        {/* <FloatingButton/> */}

        <FloatingButtons/>

        {/* <LowerTestimonial/> */}



        <Footer/>
        </div>
    )
}

export default Home;