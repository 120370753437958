import { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { motion } from "framer-motion";

const OfferAlertModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const hasSeenOffer = localStorage.getItem("hasSeenOffer");
    if (!hasSeenOffer) {
      setIsOpen(true);
      localStorage.setItem("hasSeenOffer", "true");
    }
  }, []);

  return (
    <Dialog 
      open={isOpen} 
      onClose={() => setIsOpen(false)} 
      className="fixed inset-0 flex items-center justify-center z-[1000] bg-black/40 backdrop-blur-md"
    >
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ duration: 0.3, ease: "easeOut" }}
        className="bg-white shadow-2xl rounded-2xl p-6 max-w-md w-full text-center relative"
      >
        {/* Close Button */}
        <button 
          className="absolute top-3 right-4 text-gray-500 hover:text-gray-700 text-lg"
          onClick={() => setIsOpen(false)}
        >
          ✖
        </button>

        {/* Offer Content */}
        <h2 className="text-2xl font-bold text-primary mb-2">🎉 Limited-Time Offer!</h2>
        <p className="text-gray-600 text-lg">
          Get <span className="font-semibold text-green-500">50% OFF</span> on all premium courses!  
          Enroll now and upgrade your skills. 🚀
        </p>

        {/* Call to Action */}
        <button 
          onClick={() => setIsOpen(false)}
          className="mt-4 bg-gradient-to-r from-blue-500 to-indigo-600 text-white font-semibold px-6 py-2 rounded-lg shadow-md hover:scale-105 transition-transform duration-200"
        >
          Claim Offer
        </button>
      </motion.div>
    </Dialog>
  );
};

export default OfferAlertModal;
