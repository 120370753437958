import React, { useState, useEffect } from 'react';
import './Scholarship.css';
import Navbar from '../components/Navbar';
import Footer from '../components/MyFooter';

const Scholarship = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    address: '',
    academicLevel: '',
    institution: '',
    gpa: '',
    essay: '',
    documents: [],
  });

  const [submitted, setSubmitted] = useState(false);
  const [countdown, setCountdown] = useState('');

  // Countdown Timer for Deadline
  useEffect(() => {
    const deadline = new Date('March 31, 2025 23:59:59').getTime();
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const timeLeft = deadline - now;

      if (timeLeft < 0) {
        clearInterval(interval);
        setCountdown('Application Closed');
        return;
      }

      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      setCountdown(`${days} days, ${hours} hours left`);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'documents') {
      setFormData({
        ...formData,
        documents: Array.from(files),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    console.log(formData);
  };

  return (
    <>
      <Navbar />
      <div className="scholarship-container">
        <div className="scholarship-header">
          <h1>Scholarship Application</h1>
          <p>Your opportunity to shine and secure financial aid for education.</p>
        </div>

        <div className="countdown">
          <h3>Application Deadline: <span>{countdown}</span></h3>
        </div>

        {submitted ? (
          <div className="confirmation-message">
            <h2>🎉 Application Submitted Successfully!</h2>
            <p>We will review your application and contact you soon.</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="scholarship-form-grid">
              <div className="scholarship-form-group">
                <label htmlFor="fullName">Full Name</label>
                <input type="text" id="fullName" name="fullName" value={formData.fullName} onChange={handleChange} required />
              </div>

              <div className="scholarship-form-group">
                <label htmlFor="email">Email Address</label>
                <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
              </div>

              <div className="scholarship-form-group">
                <label htmlFor="phone">Phone Number</label>
                <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
              </div>

              <div className="scholarship-form-group">
                <label htmlFor="address">Address</label>
                <input type="text" id="address" name="address" value={formData.address} onChange={handleChange} required />
              </div>

              <div className="scholarship-form-group">
                <label htmlFor="academicLevel">Academic Level</label>
                <select id="academicLevel" name="academicLevel" value={formData.academicLevel} onChange={handleChange} required>
                  <option value="">Select Academic Level</option>
                  <option value="High School">High School</option>
                  <option value="Undergraduate">Undergraduate</option>
                  <option value="Graduate">Graduate</option>
                </select>
              </div>

              <div className="scholarship-form-group">
                <label htmlFor="institution">Current Institution</label>
                <input type="text" id="institution" name="institution" value={formData.institution} onChange={handleChange} required />
              </div>

              <div className="scholarship-form-group">
                <label htmlFor="gpa">GPA</label>
                <input type="number" id="gpa" name="gpa" step="0.01" value={formData.gpa} onChange={handleChange} required />
              </div>

              <div className="scholarship-form-group scholarship-full-width">
                <label htmlFor="essay">Personal Essay</label>
                <textarea id="essay" name="essay" value={formData.essay} onChange={handleChange} placeholder="Tell us about yourself, your achievements, and why you deserve this scholarship." required />
              </div>

              <div className="scholarship-form-group scholarship-full-width">
                <label htmlFor="documents">Upload Supporting Documents</label>
                <input type="file" id="documents" name="documents" onChange={handleChange} multiple required />
                <small>You can upload multiple files (e.g., transcripts, certificates).</small>

                {/* Display Uploaded File Names */}
                <ul>
                  {formData.documents.map((file, index) => (
                    <li key={index}>{file.name}</li>
                  ))}
                </ul>
              </div>
            </div>

            <button type="submit" className="scholarship-submit-button">
              Submit Application
            </button>
          </form>
        )}

        <div className="testimonials">
          <h2>Success Stories</h2>
          <div className="testimonial-card">
            <p>"Thanks to this scholarship, I was able to pursue my dream degree!"</p>
            <h4>- John Doe, 2023 Recipient</h4>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Scholarship;
