import axios from "axios";
import config from "../config";

// Exam data fetching service
export const fetchExamData = async (token) => {
  try {
    const response = await axios.get(`${config.apiUrl}exam-attempts/`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data; // Directly return the data
  } catch (error) {
    console.error("Error fetching exam data:", error.message);
    throw error; // Throw the error to handle it in the calling component
  }
};

export const fetchExamCategory = async () => {
  try {
    const response = await axios.get(`${config.apiUrl}exam-category/`, {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching exam category:", error.message);
    throw error;
  }
};