import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';
import AuthContext from '../../context/AuthContext';

const ExamList = ({courses,onEditExam}) => {
    const [exams, setExams] = useState([]);
    const { authTokens } = useContext(AuthContext);
    const token = authTokens?.access;

    useEffect(() => {
        axios.get(`${config.apiUrl}exams`,{
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then(response => {
                setExams(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the exams!', error);
            });
    }, []);

    const handleDelete = (id) => {
        // Implement delete functionality here
        console.log(`Delete exam with id: ${id}`);
        axios.delete(`${config.apiUrl}exams/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                setExams(exams.filter(exam => exam.id !== id));
                console.log('Exam deleted successfully');
            })
            .catch(error => {
                console.error('There was an error deleting the exam!', error);
            });

    };

    const handleEdit = (id) => {
        // Implement edit functionality here
        console.log(`Edit exam with id: ${id}`);
    };
    const getCourseTitle = (courseId) => {
        const course = courses.find(course => course.courseId === courseId);
        return course ? course.courseTitle : 'Unknown Course';
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Exam List</h1>
            <table className="min-w-full bg-white border border-gray-200">
                <thead>
                    <tr>
                        <th className="py-2 px-4 border-b">ID</th>
                        <th className="py-2 px-4 border-b">Exam Title</th>
                        <th className="py-2 px-4 border-b">Course Title</th>
                    
                        <th className="py-2 px-4 border-b">Description</th>
                        <th className="py-2 px-4 border-b">Duration (minutes)</th>
                        <th className="py-2 px-4 border-b">Pass Score</th>
                        <th className="py-2 px-4 border-b">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {exams.map(exam => (
                        <tr key={exam.id} className="hover:bg-gray-100">
                            <td className="py-2 px-4 border-b">{exam.id}</td>
                            <td className="py-2 px-4 border-b">{exam.title}</td>
                            {/* <td className="py-2 px-4 border-b">{exam.courseId}</td> */}
                            <td className="py-2 px-4 border-b">{getCourseTitle(exam.courseId)}</td>
                            <td className="py-2 px-4 border-b">{exam.description}</td>
                            <td className="py-2 px-4 border-b">{exam.duration_minutes}</td>
                            <td className="py-2 px-4 border-b">{exam.pass_score}</td>
                            <td className="py-2 px-4 border-b">
                                <button
                                    className="bg-blue-500 text-white px-2 py-1 rounded m-2"
                                    onClick={() => onEditExam(exam.id)}
                                >
                                    Edit
                                </button>
                                <button
                                    className="bg-red-500 text-white px-2 py-1 rounded"
                                    onClick={() => handleDelete(exam.id)}
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ExamList;