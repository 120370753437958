import React, { useState, useEffect } from 'react';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { FaChevronDown, FaPlus, FaEdit, FaTrash } from 'react-icons/fa';
import { Dialog } from '@headlessui/react';
import axios from 'axios';
import { fetchCourseFaq } from '../../services/courseService';
import config from '../../config';
import { toast } from 'react-toastify';
import Button from "@mui/material/Button";

const AddCourseFaq = ({ courseId }) => {
  const [faqs, setFaqs] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [editFaq, setEditFaq] = useState(null);

  const showAlert = (type, message) => {
    toast.success(message, {
      position: 'top-center',
    });
  };

  useEffect(() => {
    const getFaqs = async () => {
      try {
        const data = await fetchCourseFaq(courseId);
        setFaqs(data);
      } catch (error) {
        console.error('Error fetching FAQs:', error);
      }
    };
    getFaqs();
  }, [courseId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${config.apiUrl}CourseFaq/`, { question, ans: answer, courseId });
      setIsOpen(false);
      setQuestion('');
      setAnswer('');
      const data = await fetchCourseFaq(courseId);
      setFaqs(data);
      showAlert("success", "FAQ added successfully!");
    } catch (error) {
      console.error('Error adding FAQ:', error);
      showAlert("error", "❌ Failed to save FAQ.");
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${config.apiUrl}CourseFaq/${editFaq.id}/`, { courseId,question, ans: answer });
      setIsEditOpen(false);
      setEditFaq(null);
      setQuestion('');
      setAnswer('');
      const data = await fetchCourseFaq(courseId);
      setFaqs(data);
      showAlert("success", "FAQ updated successfully!");
    } catch (error) {
      console.error('Error updating FAQ:', error);
      showAlert("error", "❌ Failed to update FAQ.");
    }
  };

  const handleDelete = async (faqId) => {
    try {
      await axios.delete(`${config.apiUrl}CourseFaq/${faqId}/`);
      setFaqs(faqs.filter(faq => faq.id !== faqId));
      showAlert("success", "FAQ deleted successfully!");
    } catch (error) {
      console.error('Error deleting FAQ:', error);
      showAlert("error", "❌ Failed to delete FAQ.");
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-4">
      <h2 className="text-2xl font-bold text-center mb-6">Course FAQs</h2>
      <Button variant="contained" color="primary" onClick={() => setIsOpen(true)} disabled={!courseId}>
        <FaPlus /> Add FAQ
      </Button>

      <div className="space-y-4 my-2">
        {faqs.map((faq) => (
          <Disclosure key={faq.id}>
            {({ open }) => (
              <div className="border rounded-lg shadow-md">
                <DisclosureButton className="flex w-full justify-between items-center p-4 text-lg font-medium bg-gray-100 hover:bg-gray-200 rounded-lg">
                  <span>{faq.question}</span>
                  <FaChevronDown className={`w-5 h-5 transition-transform duration-300 ${open ? 'rotate-180' : ''}`} />
                </DisclosureButton>
                <DisclosurePanel className="p-4 text-gray-600 bg-white border-t text-left">
                  {faq.ans}
                  <div className="flex justify-between space-x-2 mt-2">
                    <button onClick={() => { setEditFaq(faq); setQuestion(faq.question); setAnswer(faq.ans); setIsEditOpen(true); }} className="text-blue-500 hover:text-blue-700">
                      <FaEdit  size={20}/>
                    </button>
                    <button onClick={() => handleDelete(faq.id)} className="text-red-500 hover:text-red-700">
                      <FaTrash size={20}/>
                    </button>
                  </div>
                </DisclosurePanel>
              </div>
            )}
          </Disclosure>
        ))}
      </div>

      {isOpen && (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h3 className="text-xl font-bold mb-4">Add New FAQ</h3>
            <form>
              <label className="block mb-2">Question:
                <input type="text" value={question} onChange={(e) => setQuestion(e.target.value)} required className="w-full mt-1 p-2 border rounded-lg" />
              </label>
              <label className="block mb-4">Answer:
                <textarea value={answer} onChange={(e) => setAnswer(e.target.value)} required className="w-full mt-1 p-2 border rounded-lg" />
              </label>
              <div className="flex flex-row justify-between gap-2">
                <button onClick={() => setIsOpen(false)} className="px-4 py-2 bg-gray-400 text-white rounded-lg hover:bg-gray-500">Cancel</button>
                <button onClick={handleSubmit} className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">Submit</button>
              </div>
            </form>
          </div>
        </Dialog>
      )}

      {isEditOpen && (
        <Dialog open={isEditOpen} onClose={() => setIsEditOpen(false)} className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h3 className="text-xl font-bold mb-4">Edit FAQ</h3>
            <form>
              <label className="block mb-2">Question:
                <input type="text" value={question} onChange={(e) => setQuestion(e.target.value)} required className="w-full mt-1 p-2 border rounded-lg" />
              </label>
              <label className="block mb-4">Answer:
                <textarea value={answer} onChange={(e) => setAnswer(e.target.value)} required className="w-full mt-1 p-2 border rounded-lg" />
              </label>
              <div className="flex justify-end gap-2">
                <button onClick={() => setIsEditOpen(false)} className="px-4 py-2 bg-gray-400 text-white rounded-lg hover:bg-gray-500">Cancel</button>
                <button onClick={handleEditSubmit} className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">Save</button>
              </div>
            </form>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default AddCourseFaq;
