// CourseControl.jsx
import React, { useContext, useEffect, useState } from 'react';
import './CourseControl.css';
import AuthContext from '../../context/AuthContext';
import axios from 'axios';
import config from '../../config';
import { Link } from 'react-router-dom';

const CourseControl = ({onEditCourse,allPayments}) => {
    const { user } = useContext(AuthContext);
    console.log("allPayments",allPayments)
    const [courses, setCourses] = useState([
        {
            id: 1,
            name: 'React Fundamentals',
            instructor: 'John Doe',
            duration: '8 weeks',
            students: 125,
            image: '/api/placeholder/400/320'
        },
        {
            id: 2,
            name: 'Advanced JavaScript',
            instructor: 'Jane Smith',
            duration: '10 weeks',
            students: 89,
            image: '/api/placeholder/400/320'
        },
        // Add more sample courses as needed
    ]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingCourse, setEditingCourse] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const initialFormState = {
        name: '',
        instructor: '',
        duration: '',
        students: '',
        image: '/api/placeholder/400/320'
    };
    useEffect(() => {
        const getCourseData = axios.get(`${config.apiUrl}courseapi`)
            .then((response) => {
                const myCourse =response?.data.filter(course=>course.instructorId==user?.user_id)
                setCourses(myCourse)
                console.log("all courses", response.data)
            })


    }, [user])


    const [formData, setFormData] = useState(initialFormState);

    const handleOpenModal = (course = null) => {
        if (course) {
            setEditingCourse(course);
            setFormData(course);
        } else {
            setEditingCourse(null);
            setFormData(initialFormState);
        }
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setEditingCourse(null);
        setFormData(initialFormState);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (editingCourse) {
            // Update existing course
            setCourses(prev => prev.map(course =>
                course.id === editingCourse.id ? { ...formData, id: course.id } : course
            ));
        } else {
            // Add new course
            setCourses(prev => [...prev, { ...formData, id: Date.now() }]);
        }
        handleCloseModal();
    };

    const handleDelete = (courseId) => {
        if (window.confirm(`Are you sure you want to delete this course?${courseId}`)) {
            axios.delete(`${config.apiUrl}courseapi/${courseId}`)
            .then(response => {
                console.log('Course deleted successfully', response.data);
            })
            .catch(error => {
                console.error('There was an error deleting the course!', error);
            });
            setCourses(prev => prev.filter(course => course.courseId !== courseId));
        }
        
    };

    const filteredCourses = courses.filter(course =>
        course.courseTitle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        course.topicList?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="course-control">
            <div className="course-header">
                <h1 className="course-title">Course Management</h1>
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Search courses..."
                        className="search-input"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <span className="search-icon">🔍</span>
                </div>
               
            </div>

            <div className="courses-grid">
                {filteredCourses.map(course => (
                    <div key={course.id} className="course-card">
                        <div className="course-content">
                            <h2 className="course-name">{course.courseTitle}</h2>
                            <div className="course-info">
                                <p>Price: <b>

                                    {course.courseFee - course.courseDiscount} Tk 
                                </b></p>
                                <p>Total Sell No: <b>
                                    {allPayments.filter(payment=>payment.courseId ==course.courseId).length} 
                                </b></p>
                               
                            </div>
                            <div className="course-actions">
                                 
                                <button
                                    className="action-btn edit-btn"
                                    onClick={()=>onEditCourse(course.courseId)}
                                    >
                                    ✏️ Edit
                                </button>
                                   
                                <button
                                    className="action-btn delete-btn"
                                    onClick={() => handleDelete(course.courseId)}
                                >
                                    🗑️ Delete
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            
        </div>
    );
};

export default CourseControl;