import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import physics from "../../assets/Physics.jpg";

// Custom styles
const StyledParentTabs = styled(Tabs)({
  backgroundColor: "#F5F5F5",
  borderRadius: "8px",
  padding: "5px",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  marginBottom: "20px",
});

const StyledParentTab = styled(Tab)(({ theme }) => ({
  textTransform: "capitalize",
  fontSize: "16px",
  fontWeight: "bold",
  margin: "0 5px",
  color: "black",
  "&.Mui-selected": {
    color: "white",
    fontWeight: "bold",
    backgroundColor: "#FBB03B",
    borderRadius: "8px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
  "&:hover": {
      backgroundColor: "#FBB03B",
    },
}));

const StyledChildTabs = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: "10px",
  marginBottom: "20px",
  "& button": {
    display: "block !important", // Ensure visibility
    visibility: "visible !important",
    backgroundColor: "#f7cb83",
    padding: "10px 20px",
    borderRadius: "8px",
    fontWeight: "bold",
    color: "black",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FBB03B",
    },
    "&.active": {
      backgroundColor: "#FBB03B",
      color: "#FFF",
    },
  },
}));

const CourseCard = styled(Paper)(({ theme }) => ({
  padding: "20px",
  borderRadius: "12px",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
  "& img": {
    width: "100%",
    height: "150px",
    objectFit: "cover",
    borderRadius: "8px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "15px",
    "& img": {
      height: "120px",
    },
  },
}));

const CourseTabs = () => {
  const [parentTab, setParentTab] = useState(0);
  const [childTab, setChildTab] = useState(0);
  const tabsData = [
    {
      "id": 1,
      "name": "Academic",
      "categories": [
        {
          "name": "Pre School",
          "courses": [
            {
              "title": "Excel 101",
              "author": "John Doe",
              "image": physics
            }
          ]
        },
        {
          "name": "Primary School",
          "courses": [
            {
              "title": "Mastering Excel",
              "author": "Jane Doe",
              "image": physics
            }
          ]
        },
        {
          "name": "Secondary School",
          "courses": [
            {
              "title": "Excel 101",
              "author": "John Doe",
              "image": physics
            }
          ]
        },
        {
          "name": "SSC",
          "courses": [
            {
              "title": "Mastering Excel",
              "author": "Jane Doe",
              "image": physics
            }
          ]
        },
        {
          "name": "HSC",
          "courses": [
            {
              "title": "Excel 101",
              "author": "John Doe",
              "image": physics
            }
          ]
        },
        {
          "name": "English Medium",
          "courses": [
            {
              "title": "Mastering Excel",
              "author": "Jane Doe",
              "image": physics
            }
          ]
        }
      ]
    },
    {
      "id": 2,
      "name": "Admission",
      "categories": [
        {
          "name": "NDC Admission",
          "courses": [
            {
              "title": "Roadmaps for NDC",
              "author": "Kyle Pew",
              "image": physics
            }
          ]
        },
        {
          "name": "College Admission",
          "courses": [
            {
              "title": "All in One",
              "author": "Kyle Pew",
              "image": physics
            }
          ]
        },
        {
          "name": "Varsity Admission",
          "courses": [
            {
              "title": "Varsity Admission Test",
              "author": "Kyle Pew",
              "image": physics
            }
          ]
        },
        {
          "name": "Engineering Admission",
          "courses": [
            {
              "title": "Engineering 101",
              "author": "Kyle Pew",
              "image": physics
            }
          ]
        },
        {
          "name": "School Admission",
          "courses": [
            {
              "title": "All for Pre-Schools",
              "author": "Kyle Pew",
              "image": physics
            }
          ]
        }
      ]
    },

    {
      "id": 2,
      "name": "Language",
      "categories": [
        {
          "name": "Arabic Language",
          "courses": [
            {
              "title": "For All Ages and Levels",
              "author": "Kyle Pew",
              "image": physics
            }
          ]
        },
        {
          "name": "Learn English",
          "courses": [
            {
              "title": "All in One",
              "author": "Kyle Pew",
              "image": physics
            }
          ]
        },
        {
          "name": "Learn Chinese",
          "courses": [
            {
              "title": " Chinese for Alls ",
              "author": "Kyle Pew",
              "image": physics
            }
          ]
        },
        
      ]
    },

    {
      "id": 2,
      "name": "Technical Skills",
      "categories": [
        {
          "name": "Programming Languages",
          "courses": [
            {
              "title": " Python for Beginners",
              "author": "Kyle Pew",
              "image": physics
            },
            {
              "title": " Java Advanced",
              "author": "Kyle Pew",
              "image": physics
            }
          ]
        },
        {
          "name": "AI and Machine Learning",
          "courses": [
            {
              "title": "All in One",
              "author": "Kyle Pew",
              "image": physics
            }
          ]
        },
        {
          "name": " Data Science",
          "courses": [
            {
              "title": " Data Science for All",
              "author": "Kyle Pew",
              "image": physics
            }
          ]
        },
        
      ]
    }
  ];
  const [loading, setLoading] = useState(true);

  const handleParentTabChange = (event, newValue) => {
    setParentTab(newValue);
    setChildTab(0); // Reset child tab when parent tab changes
  };

  const handleChildTabChange = (index) => {
    setChildTab(index);
  };

  // Fetch data from backend
//   useEffect(() => {
//     const fetchTabsData = async () => {
//       try {
//         // Simulate fetching data from a backend API
//         const response = await fetch("https://api.example.com/lms-tabs"); // Replace with your API URL
//         const data = await response.json();

//         // Example response structure
//         // [
//         //   {
//         //     id: 1,
//         //     name: 'Parent Tab 1',
//         //     categories: [
//         //       {
//         //         name: 'Excel Basics',
//         //         courses: [{ title: 'Excel 101', author: 'John Doe', image: 'url' }]
//         //       },
//         //       { name: 'Advanced Excel', courses: [...] }
//         //     ]
//         //   },
//         //   { id: 2, name: 'Parent Tab 2', categories: [...] }
//         // ]

//         setTabsData(data);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching tabs data:", error);
//         setLoading(false);
//       }
//     };

//     fetchTabsData();
//   }, []);

  return (
    <Box sx={{ maxWidth: "1200px", margin: "0 auto", padding: "20px" }}>
      <Typography
        variant="h4"
        sx={{ fontWeight: "bold", marginBottom: "20px", textAlign: "center" }}
      >
        All the Skills You Need in One Place
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ marginBottom: "20px", textAlign: "center", color: "#666" }}
      >
        From critical skills to technical topics, explore courses to support
        your professional development.
      </Typography>

      {/* Loading Spinner */}
      {/* {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : ( */}
        <>
          {/* Parent Tabs */}
          <StyledParentTabs
            value={parentTab}
            onChange={handleParentTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Parent Tabs"
          >
            {tabsData.map((parent, index) => (
              <StyledParentTab key={parent.id} label={parent.name} />
            ))}
          </StyledParentTabs>

          {/* Child Tabs (as card/tablet-like buttons) */}
          <StyledChildTabs>
            {tabsData[parentTab].categories.map((category, index) => (
              <button
                key={index}
                className={index === childTab ? "active" : ""}
                onClick={() => handleChildTabChange(index)}
              >
                {category.name}
              </button>
            ))}
          </StyledChildTabs>

          {/* Content Below Child Tabs */}
          <Grid container spacing={3}>
            {tabsData[parentTab].categories[childTab].courses.map(
              (course, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <CourseCard>
                    <img src={course.image} alt={course.title} />
                    <Typography
                      variant="h6"
                      sx={{ marginTop: "10px", fontWeight: "bold" }}
                    >
                      {course.title}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ color: "#666" }}>
                      {course.author}
                    </Typography>
                  </CourseCard>
                </Grid>
              )
            )}
          </Grid>
        </>
    </Box>
  );
};

export default CourseTabs;
