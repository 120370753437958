import React, { useState } from "react";
import "./CSS/contact.css";
import Navbar from "../components/Navbar";
import Footer from "../components/MyFooter";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    console.log(formData);
  };

  return (
    <>
      <Navbar />
      <div className="contact-container">
        {/* Header Section */}
        <div className="contact-header">
          <h1>Contact Us</h1>
          <p>Have questions? We're here to help!</p>
        </div>

        <div className="contact-content">
          {/* Left Side: Contact Form */}
          <div className="contact-form">
            {submitted ? (
              <div className="confirmation-message">
                <h2>🎉 Thank you for reaching out!</h2>
                <p>We will get back to you as soon as possible.</p>
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <label htmlFor="name">Full Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="message">Your Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                  required
                />

                <button type="submit" className="contact-submit-button">
                  Send Message
                </button>
              </form>
            )}
          </div>

          {/* Right Side: Contact Info */}
          <div className="contact-info">
            <h2>📍 Our Office</h2>
            <p>123 Scholarship Street, New York, NY 10001</p>

            <h2>📧 Email</h2>
            <p>support@scholarshiphub.com</p>

            <h2>📞 Phone</h2>
            <p>+1 (555) 123-4567</p>

            <h2>🌍 Follow Us</h2>
            <div className="contact-socials">
              <a href="#" className="social-icon">🌐 Facebook</a>
              <a href="#" className="social-icon">📷 Instagram</a>
              <a href="#" className="social-icon">🐦 Twitter</a>
              <a href="#" className="social-icon">💼 LinkedIn</a>
            </div>
          </div>
        </div>

        {/* Google Map */}
        <div className="contact-map">
          <iframe
            title="office-location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.911434008398!2d-74.00601548459397!3d40.712775279330455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a317f66a1a3%3A0xbee7fd38d0c7db3!2sNew+York%2C+NY%2C+USA!5e0!3m2!1sen!2s!4v1616582394821!5m2!1sen!2s"
            width="100%"
            height="350"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
