import React, { useContext, useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import { fetchExamCategory } from '../../services/examService';
import config from '../../config';
import AuthContext from '../../context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { AiOutlineClose } from 'react-icons/ai';

const EditExamForm = ({ courses, examId }) => {
    const { user, authTokens } = useContext(AuthContext);
    const token = authTokens?.access;

    const showAlert = (type, message) => {
        toast(message, { type, position: 'top-center' });
    };

    const [examData, setExamData] = useState({
        courseId: '',
        moduleId: '',
        title: '',
        category: '',
        description: '',
        duration_minutes: '',
        pass_score: '',
        difficulty: 'MEDIUM',
        is_active: true,
        questions: [],
    });

    const [examCategories, setExamCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            const categories = await fetchExamCategory();
            setExamCategories(categories);
        };
        fetchCategories();
    }, []);

    useEffect(() => {
        const fetchExamData = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}exams/${examId}/`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setExamData(response.data);
            } catch (error) {
                showAlert('error', 'Failed to load exam data');
            }
        };
        fetchExamData();
    }, [examId, token]);

    const handleExamChange = (e) => {
        setExamData({ ...examData, [e.target.name]: e.target.value });
    };

    const handleQuestionChange = (index, e) => {
        const updatedQuestions = [...examData.questions];
        updatedQuestions[index] = { ...updatedQuestions[index], [e.target.name]: e.target.value };
        setExamData({ ...examData, questions: updatedQuestions });
    };

    const handleChoiceChange = (questionIndex, choiceIndex, e) => {
        const updatedQuestions = [...examData.questions];
        updatedQuestions[questionIndex].choices[choiceIndex] = {
            ...updatedQuestions[questionIndex].choices[choiceIndex],
            [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
        };
        console.log("updatedQuestions", updatedQuestions)
        setExamData({ ...examData, questions: updatedQuestions });
    };

    const addNewQuestion = () => {
        const newQuestion = {
            id: null,
            text: '',
            points: 1,
            choices: [],
        };
        setExamData((prev) => ({
            ...prev,
            questions: [...prev.questions, newQuestion],
        }));
    };

    const addNewChoice = (questionIndex) => {
        const updatedQuestions = [...examData.questions];
        if (!updatedQuestions[questionIndex].id) {
            showAlert('warning', 'Save the question first before adding choices.');
            return;
        }
        const newChoice = { question: updatedQuestions[questionIndex].id, text: '', is_correct: false };
        // const newChoice = { id:null, text: '', is_correct: false };
        updatedQuestions[questionIndex].choices.push(newChoice);
        setExamData({ ...examData, questions: updatedQuestions });
    };

    const updateExam = () => {
        axios.put(`${config.apiUrl}exams/${examId}/`, examData, {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        })
            .then(() => showAlert('success', 'Exam updated successfully'))
            .catch(() => showAlert('error', 'Failed to update exam'));
    };

    const saveOrUpdateQuestion = (index) => {
        const question = examData.questions[index];
        const method = question.id ? 'PUT' : 'POST';
        const url = question.id ? `${config.apiUrl}questions/${question.id}/` : `${config.apiUrl}questions/`;

        axios({
            method,
            url,
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            data: { ...question, exam: examData.id },
        })
            .then((response) => {
                const updatedQuestions = [...examData.questions];
                updatedQuestions[index].id = response.data.id;
                setExamData({ ...examData, questions: updatedQuestions });
                showAlert('success', question.id ? 'Question updated' : 'Question saved');
            })
            .catch(() => showAlert('error', 'Failed to save question'));
    };
    const deleteQuestion = async (questionIndex) => {
        const question = examData.questions[questionIndex];

        if (question.id) {
            // If the question is saved in DB, delete from API
            try {
                await axios.delete(`${config.apiUrl}questions/${question.id}/`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                showAlert('success', 'Question deleted successfully');
            } catch (error) {
                showAlert('error', 'Failed to delete question');
                return;
            }
        }

        // Remove from state
        const updatedQuestions = examData.questions.filter((_, index) => index !== questionIndex);
        setExamData({ ...examData, questions: updatedQuestions });
    };
    const saveOrUpdateChoice = (questionIndex, choiceIndex) => {
        const choice = examData.questions[questionIndex].choices[choiceIndex];
        const method = choice.id ? 'PUT' : 'POST';
        const url = choice.id ? `${config.apiUrl}question-choice/${choice.id}/` : `${config.apiUrl}question-choice/`;
        console.log("choice data is ", choice)
        axios({
            method,
            url,
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            data: choice,
        })
            .then((response) => {
                const updatedQuestions = [...examData.questions];
                updatedQuestions[questionIndex].choices[choiceIndex].id = response.data.id;
                setExamData({ ...examData, questions: updatedQuestions });
                showAlert('success', choice.id ? 'Choice updated' : 'Choice saved');
            })
            .catch(() => showAlert('error', 'Failed to save choice'));
    };
    const deleteChoice = async (questionIndex, choiceIndex) => {
        const choice = examData.questions[questionIndex].choices[choiceIndex];

        if (choice.id) {
            // If the choice is saved in DB, delete from API
            try {
                await axios.delete(`${config.apiUrl}question-choice/${choice.id}/`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                showAlert('success', 'Choice deleted successfully');
            } catch (error) {
                showAlert('error', 'Failed to delete choice');
                return;
            }
        }

        // Remove from state
        const updatedQuestions = [...examData.questions];
        updatedQuestions[questionIndex].choices = updatedQuestions[questionIndex].choices.filter((_, index) => index !== choiceIndex);
        setExamData({ ...examData, questions: updatedQuestions });
    };
    return (
        
            <div className="flex h-screen  mb-8">
                {/* Left Section - Exam Info */}
                <div className="w-1/3 p-4  bg-gray-100 shadow-md h-full sticky top-0">
                    <h2 className="text-xl font-semibold mb-4">Upload Exam Info</h2>
                    <form className="flex flex-col gap-3" >
                        <label>
                            Course
                            <select className="input-field" name="courseId" value={examData.courseId} onChange={handleExamChange}>
                                {courses.map((course) => (
                                    <option key={course.courseId} value={course.courseId}>{course.courseTitle}</option>
                                ))}
                            </select>
                        </label>
                        <label>
                            Title
                            <input required className="input-field" type="text" name="title" placeholder="Title" onChange={handleExamChange} value={examData.title} />
                        </label>
                        <label>
                            Category
                            <select className="input-field" name="category" onChange={handleExamChange}>
                                {examCategories.map((category) => (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                ))}
                            </select>
                        </label>
                        <label>
                            Description
                            <textarea value={examData.description} className="input-field" name="description" placeholder="Description" onChange={handleExamChange}></textarea>
                        </label>
                        <label>
                            Duration (minutes)
                            <input value={examData.duration_minutes} className="input-field" type="number" name="duration_minutes" placeholder="Duration (minutes)" onChange={handleExamChange} />
                        </label>
                        <label>
                            Pass Score
                            <input value={examData.pass_score} className="input-field" type="number" name="pass_score" placeholder="Pass Score" onChange={handleExamChange} />
                        </label>
                        <label>
                            Difficulty
                            <select value={examData.difficulty} className="input-field" name="difficulty" onChange={handleExamChange}>
                                <option value="EASY">Easy</option>
                                <option value="MEDIUM">Medium</option>
                                <option value="HARD">Hard</option>
                            </select>
                        </label>
                        <label className="flex items-center gap-2">
                            <input type="checkbox" name="is_active" checked={examData.is_active} onChange={() => setExamData({ ...examData, is_active: !examData.is_active })} />
                            Active
                        </label>
                        {/* <select className="input-field" name="courseId" value={examData.courseId} onChange={handleExamChange}>
                        {courses.map((course) => (
                            <option key={course.courseId} value={course.courseId}>{course.courseTitle}</option>
                        ))}
                    </select>
                    <input required className="input-field" type="text" name="title" placeholder="Title" onChange={handleExamChange} value={examData.title} />
                    <select className="input-field" name="category" onChange={handleExamChange}>
                        {examCategories.map((category) => (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        ))}
                    </select>
                    <textarea value={examData.description} className="input-field" name="description" placeholder="Description" onChange={handleExamChange}></textarea>
                    <input value={examData.duration_minutes} className="input-field" type="number" name="duration_minutes" placeholder="Duration (minutes)" onChange={handleExamChange} />
                    <input value={examData.pass_score} className="input-field" type="number" name="pass_score" placeholder="Pass Score" onChange={handleExamChange} />
                    <select value={examData.difficulty} className="input-field" name="difficulty" onChange={handleExamChange}>
                        <option value="EASY">Easy</option>
                        <option value="MEDIUM">Medium</option>
                        <option value="HARD">Hard</option>
                    </select> */}
                        {/* <label value={examData.is_active} className="flex items-center gap-2">
                        <input type="checkbox" name="is_active" checked={examData.is_active} onChange={() => setExamData({ ...examData, is_active: !examData.is_active })} />
                        Active
                    </label> */}

                        <Button variant='contained' color='secondary' onClick={updateExam}>
                            🚀 Update Exam
                        </Button>
                    </form>
                </div>
 
                {/* Right Section - Scrollable Questions & Choices */}
                <div className='w-2/3 flex flex-col overflow-y-auto'>
                    {examData?.questions?.map((question, qIndex) => (
                        <div className="bg-gray-400 p-6 shadow-md  my-4">

                            <div className="flex justify-end items-start ">
                                <Button variant="outlined" onClick={() => deleteQuestion(qIndex)} color="error">
                                    ❌ Delete Question
                                </Button>
                            </div>
                            <div key={qIndex} className="flex flex-row gap-2  ">

                                <div className="flex flex-col">
                                    <h3 className="text-lg font-semibold">Questions</h3>
                                    <form className="flex flex-col gap-3">
                                        <textarea value={question.text} className="input-field" name="text" placeholder="Question Text" onChange={(e) => handleQuestionChange(qIndex, e)}></textarea>
                                        <input value={question.points} className="input-field" type="number" name="points" placeholder="Points" onChange={(e) => handleQuestionChange(qIndex, e)} />
                                        <Button variant='contained' color='info' onClick={() => saveOrUpdateQuestion(qIndex)}>
                                            {question.id ? '✅ Update Question' : '💾 Save Question'}
                                        </Button>

                                    </form>
                                </div>
                                <div className=''>
                                    <h3 className="text-lg font-semibold">Choices</h3>
                                    {question?.choices?.map((choice, cIndex) => (
                                        <div key={cIndex} className="flex items-center gap-3 my-2">
                                            <input className="input-field flex-1" type="text" name="text" placeholder="Choice Text" value={choice.text} onChange={(e) => handleChoiceChange(qIndex, cIndex, e)} />
                                            <label className="flex items-center gap-2">
                                                <input type="checkbox" name="is_correct" checked={choice.is_correct} onChange={(e) => handleChoiceChange(qIndex, cIndex, e)} />
                                                Correct
                                            </label>
                                            <Button variant='contained' color='secondary' onClick={() => saveOrUpdateChoice(qIndex, cIndex)}>
                                                {choice.id ? '✅ Update' : '💾 Save'}
                                            </Button>
                                            <Button  variant='outlined' color='error' onClick={() => deleteChoice(qIndex, cIndex)}> ❌ </Button>
                                        </div>
                                    ))}
                                    <Button variant='contained' color='secondary' onClick={() => addNewChoice(qIndex)}> ➕  Add Choice</Button>
                                </div>
                            </div>
                        </div>
                    ))}
                    <Button variant='contained' color='primary' className='w-1/2' onClick={addNewQuestion}> ➕ Add New Question</Button>
                </div>
                <ToastContainer autoClose={2000} />
            </div>
        
    )
}

export default EditExamForm