import { useContext, useEffect, useState } from "react";
import { FaUserGraduate, FaBookOpen, FaChalkboardTeacher } from "react-icons/fa";
import CountUp from "react-countup";
import "./HomeCss/lms-stats.css";
import { fetchAllCourses } from "../../services/courseService";
import { fetchAllUserData } from "../../services/userService";
import AuthContext from "../../context/AuthContext";

const LMSStats = () => {
    const [allStudents, setAllStudents] = useState(0)
    const [allCourses, setAllCourses] = useState(0)
    const [allInstructors, setAllInstructors] = useState(0)
    const [loading, setLoading] = useState(true);
    const { authTokens } = useContext(AuthContext);
    const token = authTokens?.access;

    useEffect(() => {
        const fetchCourseData = async () => {
            try {
                const data = await fetchAllCourses();
                setAllCourses(data.length);
                console.log("all course lenght", data.length)
            } catch (error) {
                console.error("Error fetching courses:", error);
            }
        };

        const fetchUserData = async () => {
            try {
                const data = await fetchAllUserData(token);
                const students = data.filter(user => user.role === "Student");
                const instructors = data.filter(user => user.role === "Instructor");
                setAllStudents(students.length);
                setAllInstructors(instructors.length);
                setAllCourses(data.length);
                console.log("all User length", data)
            } catch (error) {
                console.error("Error fetching Users:", error);
            }
        };
        fetchCourseData();
        fetchUserData();
    }, []);
    const stats = {
        students: 5000,
        courses: 120,
        instructors: 50,
    };

    return (
        <section className="lms-stats">
            <div className="lms-stats-container">
                <div className="lms-stat-card">
                    <FaUserGraduate className="lms-icon" />
                    <h3>
                        <CountUp end={allStudents} duration={3} separator="," />
                    </h3>
                    <p>Students Enrolled</p>
                </div>
                <div className="lms-stat-card">
                    <FaBookOpen className="lms-icon" />
                    <h3>
                        <CountUp end={allCourses} duration={3} separator="," />
                    </h3>
                    <p>Courses Available</p>
                </div>
                <div className="lms-stat-card">
                    <FaChalkboardTeacher className="lms-icon" />
                    <h3>
                        <CountUp end={allInstructors} duration={3} separator="," />
                    </h3>
                    <p>Expert Instructors</p>
                </div>
            </div>
        </section>
    );
};

export default LMSStats;
