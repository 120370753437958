import "./NavbarStyle.css";
import { useState, useContext, useEffect, useRef } from "react";
import { MenuItems } from "./MenuItems";
//import Dropdown from "./Dropdown";
//import DropdownAdmission from "./DropdownAdmission";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
//import SignUp from "../routes/SignUp";
import logo from "../assets/logo.png"
import LanguageSelector from './LanguageSelector';
import { BsPersonCircle } from "react-icons/bs";
import { Menu, MenuItem } from "@mui/material";
import { BiSolidBackpack } from "react-icons/bi";
import AuthContext from "../context/AuthContext";
import { fetchUserData } from "../services/userService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import MenuIcon from '@material-ui/icons/Menu';
// import Button from '@mui/material/Button';

const Navbar = () => {
    const [state, setState] = useState(false);
    //const [dropdown, setDropdown] = useState(false);
    //const [dropdownAdmission, setDropdownAdmission] = useState(false);
    const { user, logoutUser,authTokens,cart } = useContext(AuthContext)
    // const token = localStorage.getItem("authTokens");
    const [userData, setUserData] = useState(null);
    const token = authTokens?.access;
    // const handleClick = () => {
    //     setState(!state)
    // }

    const { t } = useTranslation();

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `../login`;
        navigate(path);
    }
    const gotoCartPage = () => {
        let path = `../cartpage`;
        navigate(path);
    }
    const routeChangeMyAccount = () => {
        let path1 = `../userdashboard`;
        navigate(path1);
    }

    useEffect(() => {
        const getUserData = async () => {
          try {
            const data = await fetchUserData(user?.user_id, token);
            setUserData(data);
            console.log("userData",data);
          } catch (err) {
            console.log("error to fetchUserData",err)
          } 
        };
    
        getUserData();
      }, [user?.user_id, token]);

      
      
    //------------For Profile Menu-----------------
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClickProfile = (event) => {
        setAnchorEl(event.currentTarget);
    };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };
    //--------------------For Profile Menu-----------------

    const profileMenuRef = useRef(null);
    const profileIconRef = useRef(null);

    //---------------------For Profile Menu------------------
    const [openMenu, setOpenMenu] = useState(false);
    const handleClickMenu = () => {
        setOpenMenu((prev) => !prev);
    };
    const handleClose = () => {
        setOpenMenu(false);
    };
    const gotoAccountPage = () => {
        navigate('../userdashboard');
    };
    const gotoEnrolledCoursesPage = () => {
        navigate('../userdashboard');
    };
    const gotoSavedCourses = () => {
        navigate('../savedforlater');
    };
    const gotoHomePage = () => {
        navigate('../');
    };

    const handleClickOutside = (event) => {
        if (profileMenuRef.current && !profileMenuRef.current.contains(event.target) &&
            profileIconRef.current && !profileIconRef.current.contains(event.target)) {
            setOpenMenu(false);
        }
    };

    useEffect(() => {
        if (openMenu) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [openMenu]);

    //----------------------For Profile Menu-------------------------
   
    const handleClick = () =>{
        setState(!state)
    }

    return (
        <nav className="NavbarItems">

            <h1 className="navbar-logo" onClick={gotoHomePage}><img src={logo} alt="Logo" /></h1>
            
            <div className="menu-icons" onClick={handleClick}>
                <i className={state ? "fas fa-times" : "fas fa-bars"}></i>
            </div>



            <ul className={state ? "nav-menu active" : "nav-menu"} >
                {MenuItems.map((item, index) => {

                    if (item.url==='/signup'){
                        if (!user)
                            return(<Link className={item.cName} to={item.url}>{t(item.title)}</Link>)
                    }else{
                        return (
                        <li key={index}>
                            <Link className={item.cName} to={item.url}>{t(item.title)}</Link>
                        </li>

                    );
                    }
                    
                })}

                {!user && (<button className="navbar-login-button" onClick={routeChange}>LogIn</button>)}


                

            </ul>

            <div className="navbar-right-side">

            <LanguageSelector />

            {/* <FontAwesomeIcon icon={faCartShopping} className="navbar-cart"/> */}
            {/* <FontAwesomeIcon icon={faBackpack} /> */}
            {/* <BiSolidBackpack className="navbar-cart" onClick={gotoCartPage}/> */}

            <div className="navbar-cart-wrapper" onClick={gotoCartPage}>
                    <BiSolidBackpack className="navbar-cart" />
                        <span className="cart-badge">{cart.length}</span>
            </div>


            

            {user &&
                <img
                src={ userData?.userImage}
                onClick={handleClickMenu}
                alt=""
                className="navbar-profile-icons-image"
                
                />
            
            }

            
            {openMenu &&
                
                <ul
                onClose={handleClose}
                className="navbar-profile-menu-list"
                ref={profileMenuRef}
                >
                    <li onClick={gotoAccountPage}>
                        My Account
                    </li>
                   {
                    userData?.role ==="Instructor" &&
                    <li >
                       <Link to={"./teacher-dashboard"}>
                       Teacher Dashboard
                       </Link> 
                    </li>
                    }
                    <li onClick={gotoEnrolledCoursesPage}>
                        My Courses
                    </li>
                    <li onClick={gotoSavedCourses}>
                        Saved Courses
                    </li>
                    <li onClick={handleClose}>
                        Settings
                    </li>
                    <li onClick={handleClose}>
                        Exam
                    </li>
                    <li onClick={handleClose}>
                        Language
                    </li>
                    <li onClick={handleClose}>
                        Notes
                    </li>
                    <li onClick={handleClose}>
                        Help
                    </li>
                    {user &&
                    <li onClick={() => { 
                        handleClose(); 
                        logoutUser();
                        navigate('./'); 
                        }} className="navbar-profile-menu-logout">
                        Logout
                    </li>
                    }
                </ul>
                }


            </div>



        </nav>


    );
}

export default Navbar