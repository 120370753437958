import { useState } from "react";
import { FaStar, FaQuoteLeft } from "react-icons/fa";
import { motion } from "framer-motion";
import "./HomeCss/testimonials-module.css"; // Import css modules stylesheet as styles
import { Button } from "@mui/material";

const testimonials = [
    {
        name: "Sarah Johnson",
        role: "Software Engineer",
        review: "This platform has truly transformed my career! The quality of the courses is outstanding, and the instructors are incredibly knowledgeable.",
        rating: 5,
        img: "https://randomuser.me/api/portraits/women/44.jpg",
    },
    {
        name: "Michael Lee",
        role: "Marketing Specialist",
        review: "I love how engaging the lessons are! The interactive content kept me motivated throughout my learning journey.",
        rating: 4,
        img: "https://randomuser.me/api/portraits/men/46.jpg",
    },
    {
        name: "Emily Davis",
        role: "Data Analyst",
        review: "Hands down the best e-learning platform out there! The hands-on projects helped me land my dream job.",
        rating: 5,
        img: "https://randomuser.me/api/portraits/women/47.jpg",
    },
];

const Testimonials = () => {
    const [current, setCurrent] = useState(0);

    const handleNext = () => {
        setCurrent((prev) => (prev + 1) % testimonials.length);
    };

    const handlePrev = () => {
        setCurrent((prev) => (prev - 1 + testimonials.length) % testimonials.length);
    };

    return (
        <section className="testimonials">
            <h2 className="testimonials-heading">What Our Students Say</h2>
            <motion.div 
                className="testimonial-card"
                key={current}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
            >
                <FaQuoteLeft className="quote-icon" />
                <p className="review-text">"{testimonials[current].review}"</p>
                <div className="testimonial-info">
                    <img src={testimonials[current].img} alt={testimonials[current].name} className="testimonial-img" />
                    <div>
                        <h4>{testimonials[current].name}</h4>
                        <p className="role">{testimonials[current].role}</p>
                        <div className="rating">
                            {[...Array(testimonials[current].rating)].map((_, i) => (
                                <FaStar key={i} className="star-icon" />
                            ))}
                        </div>
                    </div>
                </div>
            </motion.div>
            <div className="testimonial-nav">
                <Button onClick={handlePrev} className="nav-btn">❮</Button>
                <Button onClick={handleNext} className="nav-btn">❯</Button>
            </div>
        </section>
    );
};

export default Testimonials;
