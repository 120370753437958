import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import AuthContext from "../../context/AuthContext";
import Button from "@mui/material/Button";
import { AiOutlineClose } from "react-icons/ai";
import { ToastContainer, toast } from 'react-toastify';
import config from "../../config";
import AddCourseFaq from "./AddCourseFaq";

const UploadCourseForm = () => {
  const { user, authTokens } = useContext(AuthContext);
  const token = authTokens?.access;

  const [courseData, setCourseData] = useState({
    courseTitle: "",
    bannerImg: null,
    topicList: "",
    courseDetails: "",
    instructorId: user?.user_id,
    courseType: 1,
    courseCategory: [], // Array for ManyToManyField
    courseRating: 0,
    courseFee: 0,
    courseDiscount: 0,
    courseDuration: 3,
    classNumber: 0,
  });
  const [courseId, setCourseId] = useState(null);
  const [moduleData, setModuleData] = useState([]);
  const [contentData, setContentData] = useState({});
  const [alert, setAlert] = useState({ type: '', message: '', show: false });
  const showAlert = (type, message) => {
    toast.success(message, {
      position: 'top-right',
    });
    setAlert({ type, message, show: true });
    setTimeout(() => setAlert({ type: '', message: '', show: false }), 3000); // Auto-dismiss after 3 seconds
  };
  const [categories, setCategories] = useState([]);


  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}courseCategory/`);
        setCategories(response.data);
        console.log("Course Categories:", response.data);
      } catch (error) {
        console.error("Error fetching course categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourseData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      console.log("File:", files[0]);
      setCourseData((prev) => ({ ...prev, [name]: files[0] }));
    }
  };
  const handleCategoryChange = (e) => {
    const selectedCategoryId = parseInt(e.target.value, 10); // Parse value to integer

    setCourseData((prevData) => {
      const isAlreadySelected = prevData.courseCategory.includes(selectedCategoryId);

      // Toggle the category in the array
      const updatedCategories = isAlreadySelected
        ? prevData.courseCategory.filter((id) => id !== selectedCategoryId) // Remove if already selected
        : [...prevData.courseCategory, selectedCategoryId]; // Add if not selected
      console.log("Updated Categories:", updatedCategories);
      return { ...prevData, courseCategory: updatedCategories };
    });
  };

  
  const handleSaveCourse = async (e) => {
    e.preventDefault();
    console.log("Course Data:", courseData);
    try {
      const formData = new FormData();
      Object.entries(courseData).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          console.log("Array:", key, value);
          value.forEach((v) => formData.append(`${key}`, v)); // For ManyToManyField
        } else {
          if (value !== null) {
            formData.append(key, value);
          }
        }
      });
      console.log("Form Data: checking");
      formData.forEach((value, key) => {
        console.log(key, value);
    });
      
      const response = await axios.post(
        `${config.apiUrl}upload-course/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setCourseId(response.data.courseId);
      // alert("✅ Course added successfully!");
      showAlert('success', ' Course added successfully!');

    } catch (error) {
      console.error("❌ Error adding course:", error.response?.data);
      // alert("❌ Failed to add course. Try again.");
      showAlert('error', '❌ Failed to add course. Try again.');

    }
  };

  const handleAddModule = () => {
    const newModule = {
      id: Date.now(),
      moduleTitle: "",
      modulePosition: moduleData.length + 1,
      moduleDuration: "01:00:00", // Default 1 hour
      moduleDescription: "",
      courseId: courseId,
      isSaved: false,
    };
    setModuleData((prev) => [...prev, newModule]);
  };

  const handleSaveModule = async (moduleId) => {
    const module = moduleData.find((mod) => mod.id === moduleId);
    try {
      const response = await axios.post(
        `${config.apiUrl}module/`,
        { ...module, courseId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setModuleData((prev) =>
        prev.map((mod) =>
          mod.id === moduleId
            ? { ...mod, id: response.data.moduleId, isSaved: true } // ✅ Mark as saved
            : mod
        )
      );
      showAlert('success', ' Module added successfully!');
    } catch (error) {
      console.error("❌ Error saving module:", error.response?.data);
      showAlert('error', '❌ Failed to save module.');
    }
  };

  const handleDeleteModule = async (moduleId) => {
    if (!moduleData.find((mod) => mod.id === moduleId)?.isSaved) {
      setModuleData((prev) => prev.filter((mod) => mod.id !== moduleId));
      return;
    }
    const confirmDelete = window.confirm("Are you sure you want to delete this module?");
    if (!confirmDelete) return;

    try {
      await axios.delete(`${config.apiUrl}module/${moduleId}/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setModuleData((prev) => prev.filter((mod) => mod.id !== moduleId));
      showAlert('success', ' Module deleted successfully!');
    } catch (error) {
      console.error("❌ Error deleting module:", error.response?.data);
      showAlert('error', '❌ Failed to delete module.');
    }
  };

  const handleAddContent = (moduleId) => {
    const newContent = {
      id: Date.now(),
      title: "",
      contentPosition: (contentData[moduleId]?.length || 0) + 1,
      contentType: 2,
      file: null,
      videoLink: "",
      moduleId,
      isSaved: false,
    };
    setContentData((prev) => ({
      ...prev,
      [moduleId]: [...(prev[moduleId] || []), newContent],
    }));
  };

  const handleDeleteContent = async (moduleId, contentId) => {
    if (!contentData[moduleId].find((c) => c.id === contentId)?.isSaved) {
      setContentData((prev) => ({
        ...prev,
        [moduleId]: prev[moduleId].filter((c) => c.id !== contentId),
      }));
      return;
    }
 const confirmDelete = window.confirm("Are you sure you want to delete this module?");
    if (!confirmDelete) return;
    
    try {
      await axios.delete(`${config.apiUrl}contentapi/${contentId}/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setContentData((prev) => ({
        ...prev,
        [moduleId]: prev[moduleId].filter((c) => c.id !== contentId),
      }));
      showAlert('success', 'Content deleted successfully!');
    } catch (error) {
      console.error("❌ Error deleting content:", error.response?.data);
      showAlert('error', '❌ Failed to delete content.');
    }
  };


  const handleContentChange = (e, moduleId, contentId) => {
    const { name, value, files } = e.target;
    setContentData((prev) => ({
      ...prev,
      [moduleId]: prev[moduleId].map((content) =>
        content.id === contentId
          ? { ...content, [name]: files ? files[0] : value }
          : content
      ),
    }));
  };

  const handleSaveContent = async (moduleId, contentId) => {
    const content = contentData[moduleId].find((c) => c.id === contentId);
    try {
      const formData = new FormData();
      Object.entries(content).forEach(([key, value]) => {
        if (value !== null) formData.append(key, value);
      });

      const response = await axios.post(`${config.apiUrl}contentapi/`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("content created ~", response.data);
      setContentData((prev) => ({
        ...prev,
        [moduleId]: prev[moduleId].map((c) =>
          c.id === contentId ? { ...c, id: response.data.contentId, isSaved: true } : c //  Mark as saved
        ),
      }));

      showAlert('success', ' Content saved successfully!');
    } catch (error) {
      console.error("❌ Error saving content:", error.response?.data);
      showAlert('error', '❌ Failed to save content.');
    }
  };


  return (
    <div className="flex flex-col lg:flex-row min-h-screen bg-gray-100 p-6 gap-6">


      <div className="w-full lg:w-1/2 bg-white p-6 rounded-xl shadow-md sticky top-0">
        <h2 className="text-2xl font-bold">📚 Course Info</h2>
        {/* {alert.show && (
          <Alert severity={alert.type} style={{ marginBottom: '1rem' }}>
            {alert.message}
          </Alert>
        )} */}
        <form onSubmit={handleSaveCourse} className="space-y-4">
          <input
            type="text"
            name="courseTitle"
            value={courseData.courseTitle}
            onChange={handleInputChange}
            placeholder="Course Title"
            className="w-full p-2 border rounded"
          />

          <input type="file" name="bannerImg" onChange={handleFileChange} />
          <input
            type="text"
            name="topicList"
            value={courseData.topicList}
            onChange={handleInputChange}
            placeholder="Course Topic List (comma separated)"
            className="w-full p-2 border rounded"
          />
          <textarea
            name="courseDetails"
            value={courseData.courseDetails}
            onChange={handleInputChange}
            placeholder="Course Details"
            className="w-full p-2 border rounded"
          ></textarea>
          <div className="">
              <label>Course Type</label>
              <select
                name="courseType"
                value={courseData.courseType}
                onChange={handleInputChange}
            className="w-full p-2 border rounded"

              >
                <option value="0">Free</option>
                <option value="1">Paid</option>
                <option value="2">Scholarship</option>
              </select>
            </div>
            <div className="form-group">
              <label>Course Fee</label>
              <input
                type="number"
                name="courseFee"
                value={courseData.courseFee}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="form-group">
              <label>Course Discount</label>
              <input
                type="number"
                name="courseDiscount"
                className="w-full p-2 border rounded"

                value={courseData.courseDiscount}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value >= 0 || value === "") {
                    handleInputChange(e); // Only allow non-negative values
                  }
                }}
              />
            </div>
            <div className="form-group">
              <label className="block mb-2 font-semibold">Course Category</label>
              <div className="flex flex-wrap gap-4">
                {
                categories.map((category) => (
                  <div key={category.id} className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      
                      id={category.id}
                      value={category.id}
                      checked={courseData.courseCategory.includes(category.id)}
                      onChange={handleCategoryChange}
                      className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    />
                    <label
                      htmlFor={category.id}
                      className="text-sm text-gray-700"
                    >
                      {category.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          <Button type="submit" variant="contained" color="primary" disabled={!!courseId}>
            {courseId ? " Course Added" : "🚀 Save Course"}
          </Button>
          <AddCourseFaq courseId={courseId} />
        </form>
      </div>

      <div className="w-full lg:w-1/2 bg-white p-6 rounded-xl shadow-md overflow-y-auto max-h-screen">
        <h2 className="text-2xl font-bold">📦 Modules & Contents</h2>


        {moduleData.map((module) => (
          <div key={module.id} className="border p-4 rounded-md shadow-sm mb-4 bg-amber-100">
          <p className="text-2xl"> Module -{module?.modulePosition}</p>  
            <div className="flex justify-end items-start ">
              <Button variant="outlined" onClick={() => handleDeleteModule(module.moduleId || module.id)} color="error">
                <AiOutlineClose />
              </Button>
            </div>
            <input
              type="text"
              name="moduleTitle"
              value={module.moduleTitle}
              onChange={(e) =>
                setModuleData((prev) =>
                  prev.map((mod) =>
                    mod.id === module.id ? { ...mod, moduleTitle: e.target.value } : mod
                  )
                )
              }
              placeholder="Module Title"
              className="w-full p-2 border rounded"
            />
            <Button
              variant="contained"
              color="success"
              onClick={() => handleSaveModule(module.id)}
              disabled={module.isSaved} //  Disable after save
            >
              {module.isSaved ? " Module Saved" : "💾 Save Module"} {/*  Change Button Text */}
            </Button>




            {(contentData[module.id] || []).map((content) => (
              <div key={content.id} className="p-3 mt-2 border rounded bg-gray-50 text-black">
          <p className="text-xl"> Module -{module?.modulePosition} : Content - {content?.contentPosition}</p>  
                
                <div className="flex justify-end items-start ">
                  <Button variant="contained" onClick={() => handleDeleteContent(module.moduleId || module.id, content.contentId || content.id)} color="warning">
                    <AiOutlineClose />
                  </Button>
                </div>
                <input
                  type="text"
                  name="title"
                  value={content.title}
                  onChange={(e) => handleContentChange(e, module.id, content.id)}
                  placeholder="Content Title"
                  className="w-full p-2 border rounded"
                />
                <select
                  name="contentType"
                  value={content.contentType}
                  onChange={(e) => handleContentChange(e, module.id, content.id)}
                  className="w-full p-2 border rounded"
                >
                  <option value="1">Video</option>
                  <option value="2">Video Link</option>
                  <option value="3">PDF</option>
                </select>

                {content.contentType === "1" || content.contentType === "3" ? (
                  <input
                    type="file"
                    name="file"
                    onChange={(e) => handleContentChange(e, module.id, content.id)}
                    className="w-full p-2"
                  />
                ) : (
                  <input
                  required
                    type="url"
                    name="videoLink"
                    value={content.videoLink}
                    onChange={(e) => handleContentChange(e, module.id, content.id)}
                    placeholder="Video Link"
                    className="w-full p-2 border rounded"
                  />
                )}

                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleSaveContent(module.id, content.id)}
                  disabled={content.isSaved} // ✅ Disable after save
                  className="w-1/2 mt-2"
                >
                  {content.isSaved ? "✅ Content Saved" : "💾 Save Content"} {/* ✅ Change Button Text */}
                </Button>

              </div>
            ))}
            <Button
              variant="contained"
              
              color="secondary"
              textColor="warning"
              onClick={() => handleAddContent(module.id)}
              disabled={!module.isSaved}
              className="w-full mt-2"
            >
              ➕ Add New Content
            </Button>
          </div>
        ))}
        <Button variant="contained" color="primary" onClick={handleAddModule} disabled={!courseId}>
          ➕ Add New Module
        </Button>
      </div>
      <ToastContainer autoClose={2000}/>
    </div>
  );
};

export default UploadCourseForm;
