import React, { useEffect, useState,useContext } from 'react';
import axios from 'axios';
import Navbar from "../../components/Navbar";
import Footer from "../../components/MyFooter";
import CourseLeftBar from "./CourseLeftBar";
import CourseCard from "./CourseCard";
import { Grid } from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import Course_image1 from '../../assets/LearnRectangle51.png';
import CoverImageText from "./CoverImageText";
import '../CSS/course.css';
import AuthContext from '../../context/AuthContext';
import { fetchUserData } from '../../services/userService';
import config from '../../config';
import LeftSidebarFilter from './SidebarFilter';
import SliderComponent from '../Home/SliderComponent';
import { Search } from "lucide-react";
import { motion } from "framer-motion";

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

function Course() {
    const [openFilterbarToggle, setOpenFilterbarToggle] = useState(false); // For small devices filter toggle
    const [courses, setCourses] = useState([]);
    const [userData, setUserData] = useState([])
    
    const [filteredCourses, setFilteredCourses] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedPricing, setSelectedPricing] = useState([]);

    const { user,authTokens,userInfo } = useContext(AuthContext);
    const token = authTokens?.access;
    // console.log("the info of the Present User is",userInfo)
  
// console.log("userData",userData)
    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}courseapi`);
                setCourses(response.data);
                setFilteredCourses(response.data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchCourses();
    }, []);

    const OpenSideFilterbar = () => {
        setOpenFilterbarToggle(!openFilterbarToggle);
    };

    const handleFilterChange = (selectedCategories, selectedPricing) => {
        setSelectedCategories(selectedCategories);
        setSelectedPricing(selectedPricing);

        setFilteredCourses(
            courses.filter(course => {
                const matchesCategory = selectedCategories.length === 0 ||
                    course.courseCategory.some(categoryId => selectedCategories.includes(categoryId));
                const matchesPricing = selectedPricing.length === 0 ||
                    selectedPricing.includes(course.courseType);
                const matchesSearchTerm = course?.courseTitle.toLowerCase().includes(searchTerm) || 
                                          course?.courseDetails.toLowerCase().includes(searchTerm);
                return matchesCategory && matchesPricing && matchesSearchTerm;
            })
        );
    };

    const handleSearchChange = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);

        setFilteredCourses(
            courses.filter(course => {
                const matchesCategory = selectedCategories.length === 0 ||
                    course.courseCategory.some(categoryId => selectedCategories.includes(categoryId));
                const matchesPricing = selectedPricing.length === 0 ||
                    selectedPricing.includes(course.courseType);
                const matchesSearchTerm = course.courseTitle.toLowerCase().includes(term) ||
                                          course.courseDetails.toLowerCase().includes(term);
                return matchesCategory && matchesPricing && matchesSearchTerm;
            })
        );
    };

    const [query, setQuery] = useState("");

    const handleSearch = (e) => {
        e.preventDefault();
        alert(`Searching for: ${query}`);
    };
    
    
    if (loading) return <p className="text-center text-lg">Loading...</p>;
    if (error) return <p className="text-center text-lg text-red-500">Error: {error}</p>;

    return (
        <div>
            <Navbar />

            {/* Top filter and search section */}
            <div className="course-filter-middle-part">
                <FilterListIcon onClick={OpenSideFilterbar} className="course-filter-icon" />
                <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="course-search-input"
                />
            </div>

            {/* Sidebar for filters (small device view)
            {openFilterbarToggle && (
                <CourseLeftBar 
                    openFilterbarToggle={openFilterbarToggle} 
                    OpenSideFilterbar={OpenSideFilterbar} 
                    onFilterChange={handleFilterChange} 
                />
            )} */}
            <div className="course-course-container centered"></div>

            {
                openFilterbarToggle ? (<CourseLeftBar 
                    onFilterChange={handleFilterChange}openFilterbarToggle={openFilterbarToggle} OpenSideFilterbar={OpenSideFilterbar}/>) : ("")
            }

            {/* Hero image and cover text */}
            {/* <img alt="HeroImg" src={Course_image1} className="course-cover-image centered" />
            <CoverImageText /> */}

            <SliderComponent/>

            <div className="w-full max-w-lg mx-auto flex items-center bg-white border border-gray-300 rounded-full shadow-md px-4 py-3 mt-6 hidden md:flex">
            <Search className="text-gray-500" size={20} />
            <input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                className="w-full bg-transparent outline-none px-3 text-gray-700"
                placeholder="Search for courses, topics, or instructors..."
            />
            <button
                onClick={handleSearch}
                className="bg-blue-600 text-white px-4 py-1 rounded-full font-semibold hover:bg-blue-700 transition duration-300"
            >
                Search
            </button>
            </div>

            {/* Main content: Course list with filters */}
            <div className="course-middle-container">
                {/* Left Sidebar Filter for larger screens */}
                <CourseLeftBar 
                    onFilterChange={handleFilterChange} 
                    className="course-course-leftbar" 
                />

                {/* Course grid */}
                <Grid container className="course-grid">
                    {filteredCourses.map(course => (
                            <CourseCard course={course} />
                    ))}
                </Grid>
            </div>

            <Footer />
        </div>
    );
}

export default Course;
