import React from 'react';
import 'tailwindcss/tailwind.css';


const ManageStudents = ({ allStudentsData, allPayments }) => {
    console.log("allStudentsData", allStudentsData);
    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <h2 className="text-2xl font-bold mb-4">Manage Students</h2>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white shadow-md rounded-lg">
                    <thead>
                        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                            <th className="py-3 px-6 text-left">Name</th>
                            <th className="py-3 px-6 text-left">Email</th>
                            <th className="py-3 px-6 text-left">Mobile No</th>
                            <th className="py-3 px-6 text-left">Address</th>
                            <th className="py-3 px-6 text-left">Guardian's Number</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light">
                        {allStudentsData.map((student, index) => (
                            <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                                <td className="py-3 px-6 text-left whitespace-nowrap">{student.firstName}</td>
                                <td className="py-3 px-6 text-left">{student.email}</td>
                                <td className="py-3 px-6 text-left">{student.username ||student.mobileNo}</td>
                                <td className="py-3 px-6 text-left">{student.address}</td>
                                <td className="py-3 px-6 text-left">{student.guardiansNumber}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ManageStudents;