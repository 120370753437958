import React, { useEffect, useState } from 'react';
import './coursedetalstabs.css'; // For styling
import config from '../../config';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import CourseDetailsFaq from './CourseDetailsFaq';
import { Box, Card } from '@mui/material';
import ExamPage from './ExamPage';

const CourseDetailsTabs = () => {
    const [activeTab, setActiveTab] = useState('CourseDetails');
    const { id } = useParams();
    const [course, setCourse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState(false);
    const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourses = async ()=>{
      try {
          const response = await axios.get(`${config.apiUrl}course/${id}`)
          setCourse(response.data)
      } catch (error) {
          setError(error.message)
      }
      finally{
          setLoading(false)
      }
    }
    fetchCourses();
  
  }, [id])
  if (loading) return <p className="text-center text-lg">Loading...</p>;
  if (error) return <p className="text-center text-lg text-red-500">Error: {error}</p>;

  if (!course) return <p className="text-center text-lg">Course not found</p>;

  const renderContent = () => {
    switch (activeTab) {
      case 'CourseDetails':
        return <CourseDetailsFaq courseId={course?.courseId}/>;
      case 'Syllabus':
        return <section className="course-details-tabs-course-details-box2">
        <ul className="course-details-tabs-syllabus-list-container">
        <ul className="course-details-tabs-syllabus-list">
            <li>Module 1: React Basics</li>
            <li>Module 2: State Management</li>
            <li>Module 3: React Router</li>
            <li>Module 4: Project Development</li>
        </ul>
        <ul className="course-details-tabs-syllabus-list">
            <li>Module 5: React Basics</li>
            <li>Module 6: State Management</li>
            <li>Module 7: React Router</li>
            <li>Module 8: Project Development</li>
        </ul>
        <ul className="course-details-tabs-syllabus-list">
            <li>Module 9: React Basics</li>
            <li>Module 10: State Management</li>
            <li>Module 11: React Router</li>
            <li>Module 12: Project Development</li>
        </ul>
        </ul>
    </section>;
      case 'CourseAssignments':
        return <div className='course-details-tabs-course-syllabus-part'>
        Assignment

    <Card className='course-details-tabs-course-details-box2' elevation={3}>
        <Box className='course-details-tabs-course-details-box2-1'>
            <li>HSC Examinee</li>
            <li>HSC Examinee</li>
            
        </Box>
        <Box className='course-details-tabs-course-details-box2-1'>
            <li>HSC Examinee</li>
            <li>HSC Examinee</li>
        </Box>
    </Card>
    </div>;
      case 'Exam':
        return <ExamPage exams={course?.exams}/>;
      default:
        return null;
    }
  };

  return (
    <div className="course-details-tabs-course-tabs">
      <div className="course-details-tabs-tabs">
        <button
          className={activeTab === 'CourseDetails' ? 'course-details-tabs-active' : ''}
          onClick={() => setActiveTab('CourseDetails')}
        >
          CourseDetails
        </button>
        <button
          className={activeTab === 'Syllabus' ? 'course-details-tabs-active' : ''}
          onClick={() => setActiveTab('Syllabus')}
        >
          Syllabus
        </button>
        <button
          className={activeTab === 'CourseAssignments' ? 'course-details-tabs-active' : ''}
          onClick={() => setActiveTab('CourseAssignments')}
        >
          Course/Assignments
        </button>
        <button
          className={activeTab === 'Exam' ? 'course-details-tabs-active' : ''}
          onClick={() => setActiveTab('Exam')}
        >
          Exam
        </button>
      </div>
      <div className="course-details-tabs-tab-content">{renderContent()}</div>
    </div>
  );
};

export default CourseDetailsTabs;
