import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { FaChevronUp, FaComments } from "react-icons/fa";

const FloatingButtons = (username, receiver) => {
  const [showGoUp, setShowGoUp] = useState(false);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const socket = new WebSocket(`ws://localhost:8000/ws/chat/${username}/`);

  useEffect(() => {
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setMessages((prev) => [...prev, data]);
    };

    return () => socket.close();
  }, []);

  const sendMessage = () => {
    if (message.trim()) {
      socket.send(JSON.stringify({ sender: username, receiver, message }));
      setMessage("");
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowGoUp(window.scrollY > 300);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const openLiveChat = () => {
    alert("Live Chat Clicked!");
  };

  return (
    <div className="floating-buttons">
      

      {/* Live Chat Button */}
      <button className="floating-btn chat-btn" 
        onClick={() => setIsOpen(!isOpen)}
        >
        <FaComments className="floating-btn-icon" />
      </button>

      {/* Chat Box */}
      {isOpen && (
        <div className="w-80 bg-white shadow-lg rounded-lg overflow-hidden fixed bottom-16 right-6">
          {/* Header */}
          <div style={{ backgroundColor: "#d6721a" }} className=" text-white p-4 flex items-center justify-between">
            <div className="flex items-center">
              <div className="flex -space-x-2">
                <img
                  src="https://via.placeholder.com/30"
                  className="w-8 h-8 rounded-full border"
                  alt="Profile"
                />
                <img
                  src="https://via.placeholder.com/30"
                  className="w-8 h-8 rounded-full border"
                  alt="Profile"
                />
              </div>
              <span className="ml-3 font-semibold">Chat Support</span>
            </div>
            <Button onClick={() => setIsOpen(false)}>✖</Button>
          </div>

          {/* Messages */}
          <div className="p-4 h-60 overflow-y-auto">
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`p-2 my-2 text-sm max-w-[75%] ${
                  msg.sender === username
                    ? "bg-blue-500 text-white ml-auto rounded-lg"
                    : "bg-gray-200 text-black rounded-lg"
                }`}
              >
                <strong>{msg.sender}:</strong> {msg.message}
              </div>
            ))}
          </div>

          {/* Input */}
          <div className="p-2 flex border-t">
            <input
              type="text"
              className="w-full p-2 border rounded-l-lg focus:outline-none"
              placeholder="Type a message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button
              style={{ backgroundColor: "#d6721a" }}
              className=" text-white px-4 py-2 rounded-r-lg"
              onClick={sendMessage}
            >
              ➤
            </button>
          </div>
        </div>
      )}

      {/* Go Up Button */}
      {showGoUp && (
        <button className="floating-btn" onClick={scrollToTop}>
          <FaChevronUp className="floating-btn-icon" />
        </button>
      )}

      <style jsx>{`
        .floating-buttons {
          position: fixed;
          bottom: 20px;
          right: 20px;
          display: flex;
          flex-direction: row;
          gap: 12px;
          z-index: 1000;
        }

        .floating-btn {
          width: 65px; /* Increased size */
          height: 65px;
          border-radius: 50%;
          background-color: #d6721a; /* Eye-catching orange */
          color: white;
          border: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
          transition: transform 0.2s ease, background-color 0.3s;
        }

        .floating-btn:hover {
          background-color: #8c274f;
          transform: scale(1.1);
        }

        .floating-btn-icon {
          font-size: 34px;
        }

        /* Responsive Design */
        @media (max-width: 600px) {
          .floating-btn {
            width: 50px;
            height: 50px;
          }
          .floating-btn-icon {
            font-size: 22px;
          }
        }
      `}</style>
    </div>
  );
};

export default FloatingButtons;
