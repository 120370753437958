import React, { useContext, useEffect, useState } from 'react';
import Button from "@mui/material/Button";
import { fetchExamCategory } from '../../services/examService';
import config from '../../config';
import AuthContext from '../../context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import { FaChevronDown, FaPlusCircle, FaEdit, FaTrash, FaPlusSquare } from 'react-icons/fa';
import { Dialog } from '@headlessui/react';
import AddExamCategory from './AddExamCategory';

const UploadExamForm = ({ courses }) => {
    const { user, authTokens } = useContext(AuthContext);
    const token = authTokens?.access;
    const [isOpen, setIsOpen] = useState(false);

    const showAlert = (type, message) => {
        toast(message, {
            type,
            position: 'top-center',
        });
    }
    const [exam, setExam] = useState(null);
    const [examData, setExamData] = useState({
        courseId: '',
        moduleId: '',
        title: '',
        category: '',
        description: '',
        duration_minutes: '',
        pass_score: '',
        difficulty: 'MEDIUM',
        is_active: true,
        
    });
    const [examCategories, setExamCategories] = useState([]);
    const [questionData, setQuestionData] = useState([]);
    const [choices, setChoices] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            const categories = await fetchExamCategory();
            setExamCategories(categories);
        };
        fetchCategories();
    }, []);

    const handleExamChange = (e) => {
        setExamData({ ...examData, [e.target.name]: e.target.value });
    };

    const handleQuestionChange = (index, e) => {
        const updatedQuestions = [...questionData];
        updatedQuestions[index][e.target.name] = e.target.value;
        setQuestionData(updatedQuestions);
    };
    const deleteQuestion = async (questionIndex) => {
        const question = questionData[questionIndex];

        if (question.id) {
            // If the question is saved in DB, delete from API
            try {
                await axios.delete(`${config.apiUrl}questions/${question.id}/`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                showAlert('success', 'Question deleted successfully');
            } catch (error) {
                showAlert('error', 'Failed to delete question');
                return;
            }
        }

        // Remove from state
    const updatedQuestions = questionData.filter((_, index) => index !== questionIndex);
    setQuestionData(updatedQuestions);
    };

    const handleChoiceChange = (questionIndex, choiceIndex, e) => {
        const updatedChoices = [...choices];
        updatedChoices[questionIndex][choiceIndex][e.target.name] = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        setChoices(updatedChoices);
    };

    const addNewQuestion = () => {
        if (!exam) {
            // alert("Save the question first before adding choices.");
            showAlert('warning', 'Save the Exam Info first before question .');
            return;
        }
        const newQuestion = { id: null, text: '', exam, points: 1.0, };
        setQuestionData([...questionData, newQuestion]);
        console.log("present Question", exam)
        setChoices([...choices, []]); // Ensure choices array aligns with new question
    };

    const addNewChoice = (questionIndex) => {
        if (!questionData[questionIndex].id) {
            // alert("Save the question first before adding choices.");
            showAlert('warning', 'Save the question first before adding choices.');
            return;
        }
        const newChoice = { question: questionData[questionIndex].id, text: '', is_correct: false };
        const updatedChoices = [...choices];
        updatedChoices[questionIndex].push(newChoice);
        setChoices(updatedChoices);
    };

    // Save or Update Exam
    const saveOrUpdateExam = () => {
        const method = exam ? 'PUT' : 'POST';
        const url = exam ? `${config.apiUrl}exams/${exam}/` : `${config.apiUrl}exams/`;

        fetch(url, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(examData),
        })
            .then(response => response.json())
            .then(data => {
                setExam(data.id);
                showAlert('success', exam ? 'Exam updated successfully' : 'Exam saved successfully');
            })
            .catch(error => {
                showAlert('error', 'Failed to save exam');
                console.error('Error:', error);
            });
    };




    const saveOrUpdateQuestion = (index) => {
        const question = questionData[index];

        const method = question.id ? 'PUT' : 'POST';
        const url = question.id ? `${config.apiUrl}questions/${question.id}/` : `${config.apiUrl}questions/`;

        fetch(url, {
            method,
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...question, exam }),
        })
            .then(response => response.json())
            .then(data => {
                const updatedQuestions = [...questionData];
                updatedQuestions[index].id = data.id;
                setQuestionData(updatedQuestions);
                showAlert('success', question.id ? 'Question updated successfully' : 'Question saved successfully');
            })
            .catch(error => {
                console.error('Error:', error);
                showAlert('error', 'Failed to save question');
            });
    };

    const saveOrUpdateChoice = (questionIndex, choiceIndex) => {
        const choice = choices[questionIndex][choiceIndex];
        const method = choice.id ? 'PUT' : 'POST';
        const url = choice.id ? `${config.apiUrl}question-choice/${choice.id}/` : `${config.apiUrl}question-choice/`;

        fetch(url, {
            method,
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(choice),
        })
            .then(response => response.json())
            .then(data => {
                const updatedChoices = [...choices];
                updatedChoices[questionIndex][choiceIndex].id = data.id;
                setChoices(updatedChoices);
                showAlert('success', choice.id ? 'Option updated successfully' : 'Option added successfully');
            })
            .catch(error => {
                console.error('Error:', error);
                showAlert('error', 'Failed to save option');
            });
    };
    
    const deleteChoice = async (questionIndex, choiceIndex) => {
        const choice = choices[questionIndex][choiceIndex];

        if (choice.id) {
            // If the choice is saved in DB, delete from API
            try {
                await axios.delete(`${config.apiUrl}question-choice/${choice.id}/`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                showAlert('success', 'Choice deleted successfully');
            } catch (error) {
                showAlert('error', 'Failed to delete choice');
                return;
            }
        }

        // Remove from state
        const updatedChoices = choices.map((questionChoices, qIndex) => {
            if (qIndex === questionIndex) {
                return questionChoices.filter((_, cIndex) => cIndex !== choiceIndex);
            }
            return questionChoices;
        });
        setChoices(updatedChoices);
    };
    return (
        <div className="flex h-screen">
            {/* Left Section - Exam Info */}
            <div className="w-1/3 p-6 bg-gray-100 shadow-md h-full sticky top-0">
                <h2 className="text-xl font-semibold mb-4">Upload Exam Info</h2>
                <form className="flex flex-col gap-3" >
                    <select className="input-field" name="courseId" onChange={handleExamChange}>
                        {courses.map((course) => (
                            <option key={course.courseId} value={course.courseId}>{course.courseTitle}</option>
                        ))}
                    </select>
                    <input required className="input-field" type="text" name="title" placeholder="Title" onChange={handleExamChange} />
                    <div className='flex justify-between items-center gap-2'>

                    <select className="input-field w-3/5" name="category"onChange={handleExamChange}>
                        {examCategories.map((category) => (
                            <option key={category.id} value={category.id} >{category.name}</option>
                        ))}
                    </select>
                    <AddExamCategory isOpen={isOpen} setIsOpen={setIsOpen} />
                        
                    </div>
                    <textarea className="input-field" name="description" placeholder="Description" onChange={handleExamChange}></textarea>
                    <input className="input-field" type="number" name="duration_minutes" placeholder="Duration (minutes)" onChange={handleExamChange} />
                    <input className="input-field" type="number" name="pass_score" placeholder="Pass Score" onChange={handleExamChange} />
                    <select className="input-field" name="difficulty" onChange={handleExamChange}>
                        <option value="EASY">Easy</option>
                        <option value="MEDIUM">Medium</option>
                        <option value="HARD">Hard</option>
                    </select>
                    <label className="flex items-center gap-2">
                        <input type="checkbox" name="is_active" checked={examData.is_active} onChange={() => setExamData({ ...examData, is_active: !examData.is_active })} />
                        Active
                    </label>

                    <Button variant='contained' color='secondary' onClick={saveOrUpdateExam}>
                        {exam ? 'Update Exam' : ' 🚀 Save Exam'}
                    </Button>
                </form>
            </div>

            {/* Right Section - Scrollable Questions & Choices */}
            <div className='w-2/3 flex flex-col overflow-y-auto'>
                {questionData.map((question, qIndex) => (
                    <div key={qIndex} className='bg-gray-400 p-4 shadow-md  my-2'>

                        <div className="flex justify-end items-start ">
                            <Button variant="outlined" color="error" onClick={() => deleteQuestion(qIndex)}>
                                ❌ Delete Question
                            </Button>
                        </div>
                        <div  className=" flex flex-row gap-2">

                            <div className="flex flex-col">
                                <h3 className="text-lg font-semibold">Questions</h3>
                                <form className="flex flex-col gap-3">
                                    <textarea className="input-field" name="text" placeholder="Question Text" onChange={(e) => handleQuestionChange(qIndex, e)}></textarea>
                                    <input className="input-field" type="number" name="points" placeholder="Points" onChange={(e) => handleQuestionChange(qIndex, e)} />
                                    <Button variant='contained' color='info' onClick={() => saveOrUpdateQuestion(qIndex)}>
                                        {question.id ? 'Update Question' : '💾 Save Question'}
                                    </Button>

                                </form>
                            </div>
                            <div>
                                <h3 className="text-lg font-semibold">Choices</h3>
                                {choices[qIndex]?.map((choice, cIndex) => (
                                    <div key={cIndex} className="flex items-center gap-3 mt-2">
                                        <input className="input-field flex-1" type="text" name="text" placeholder="Choice Text" value={choice.text} onChange={(e) => handleChoiceChange(qIndex, cIndex, e)} />
                                        <label className="flex items-center gap-2">
                                            <input type="checkbox" name="is_correct" checked={choice.is_correct} onChange={(e) => handleChoiceChange(qIndex, cIndex, e)} />
                                            Correct
                                        </label>
                                        <Button variant='contained' color='secondary' onClick={() => saveOrUpdateChoice(qIndex, cIndex)}>
                                            {choice.id ? 'Update ' : '💾 Save '}
                                        </Button>
                                        <Button size='small' variant='outlined' color='error'onClick={() => deleteChoice(qIndex, cIndex)} > ❌ </Button>
                                    </div>
                                ))}
                                <Button variant='contained' color='secondary' onClick={() => addNewChoice(qIndex)}> ➕ Add Choice</Button>
                            </div>
                        </div>
                    </div>
                ))}
                <Button variant='contained' color='primary' className='w-1/2' onClick={addNewQuestion}> ➕Add New Question</Button>
            </div>
            
            <ToastContainer autoClose={2000} />
        </div>
    );
};

export default UploadExamForm;
