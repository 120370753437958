import { Dialog } from '@headlessui/react';
import React from 'react'
import { FaChevronDown, FaPlusCircle, FaEdit, FaTrash, FaPlusSquare } from 'react-icons/fa';


const AddExamCategory = ({ isOpen, setIsOpen }) => {


    return (
        <div>
            <div className='bg-gray-200 p-2 items-center rounded-md  flex gap-2 justify-between' onClick={() => setIsOpen(true)} >
                New Add <FaPlusCircle />
            </div>
            {isOpen && (
                    <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30">
                      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                        <h3 className="text-xl font-bold mb-4">Add Exam Category</h3>
                        <form>
                          <label className="block mb-2">Category Name:
                            <input type="text" className="w-full mt-1 p-2 border rounded-lg" />
                          </label>
                          <label className="block mb-4"> Description:
                            <textarea  required className="w-full mt-1 p-2 border rounded-lg" />
                          </label>
                          <div className="flex flex-row justify-between gap-2">
                            <button o className="px-4 py-2 bg-gray-400 text-white rounded-lg hover:bg-gray-500" onClick={()=>setIsOpen(false)} >Cancel</button>
                            <button  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">Submit</button>
                          </div>
                        </form>
                      </div>
                    </Dialog>
                  )}
        </div>
    )
}

export default AddExamCategory