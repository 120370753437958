import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules"; 
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./slider.css"; // Custom styles for the slider
import Slide1_Pic from "../../assets/slide1.jpg";
import Slide2_Pic from "../../assets/slide2.jpg";
import Slide3_Pic from "../../assets/slide3.jpg";

const SliderComponent = () => {
  const slides = [
    {
      title: "Learning Arabic Made Easy",
      description: "Explore our courses and start learning today.",
      image: Slide1_Pic,
    },
    {
      title: "Physics Made Fun",
      description: "Join our physics course and learn with ease.",
      image: Slide2_Pic,
    },
    {
      title: "Chemistry for Beginners",
      description: "Start your chemistry journey with us.",
      image: Slide3_Pic,
    },
  ];

  return (
    <div className="slider-container">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        loop={true}
        speed={1000}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div
              className="slide"
              style={{ backgroundImage: `url(${slide.image})` }}
            >
              <div className="slider-content">
                <h1>{slide.title}</h1>
                <p>{slide.description}</p>
                <button
                className="home-slider-component-enroll-button"
                onClick={() => {
                  console.log("Enroll Now button clicked");
                }}>
                Enroll Now
              </button>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SliderComponent;
