import React, { useContext, useEffect, useState } from 'react';
import './Dashboard.css';
import CourseControl from './CourseControl';
import UploadCourseForm from './UploadCourseForm';
import ExamControl from './ExamControl';
import EditCourseForm from './EditCourseForm';
import EditExamForm from './EditExamForm'; // ✅ Import EditExamForm
import AuthContext from '../../context/AuthContext';
import axios from 'axios';
import config from '../../config';
import { fetchAllUserData, fetchUserData } from '../../services/userService';
import ManageStudents from './MangeStudents';
import DashboardContent from './DashboardContent';
import UploadExamForm from './UploadExamForm';
import { useNavigate } from 'react-router-dom';

const TeacherDashboard = () => {
    const { user, authTokens } = useContext(AuthContext);
    const token = authTokens?.access;
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [activeTab, setActiveTab] = useState('dashboard');
    const [selectedCourseId, setSelectedCourseId] = useState(null);
    const [selectedExamId, setSelectedExamId] = useState(null); // ✅ Store selected Exam ID
    const [allPayments, setAllPayments] = useState([]);
    const [courses, setCourses] = useState([]);
    const [totalEarnings, setTotalEarnings] = useState(0);
    const [totalStudents, setTotalStudents] = useState(0);
    const [allUserData, setAllUserData] = useState([]);
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            navigate('/');
            // return null;
        }
        const getUserData = async () => {
          try {
            setLoading(true);
            const data = await fetchUserData(user?.user_id, token);
            if (data.role !=="Instructor") {
                // alert("You're not Instructor")
                navigate('/');
                return null;
            }
            setUserData(data);
            setError(null);
          } catch (err) {
            setError('Failed to load user data');
          } finally {
            setLoading(false);
          }
        };
    
        getUserData();
      }, [user?.user_id, token]);

    useEffect(() => {
        const calculateTotalEarnings = () => {
            return allPayments.reduce((total, allPayment) => total + parseInt(allPayment.payment), 0);
        };
        const calculateTotalStudents = () => {
            const uniqueStudents = new Set(allPayments.map(payment => payment.userId));
            return uniqueStudents.size;
        };

        setTotalStudents(calculateTotalStudents());
        setTotalEarnings(calculateTotalEarnings());

        fetchAllUserData(token)
            .then((response) => {
                console.log("all user", response);
                const uniqueUserIds = [...new Set(allPayments.map(payment => payment.userId))];
                const filteredUsers = response.filter(user => uniqueUserIds.includes(user.user));
                setAllUserData(filteredUsers);
            })
            .catch((error) => {
                console.error("Error fetching users", error);
            });

    }, [allPayments]);

    useEffect(() => {
        axios.get(`${config.apiUrl}courseapi`)
            .then((response) => {
                const myCourses = response?.data.filter(course => course.instructorId === user?.user_id);
                setCourses(myCourses);
                console.log("All courses", myCourses);
            });
    }, [user]);

    useEffect(() => {
        axios.get(`${config.apiUrl}payment`, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then((response) => {
                const filteredPayments = response?.data.filter(payment =>
                    courses.some(course => course.courseId === payment.courseId)
                );
                setAllPayments(filteredPayments);
                console.log("Filtered payments", filteredPayments);
            })
            .catch((error) => {
                console.error("Error fetching payments", error);
            });

    }, [courses]);

    // ✅ Function to update courseId and switch to Edit Course Tab
    const handleEditCourse = (courseId) => {
        setSelectedCourseId(courseId);
        setActiveTab("edit-course");
    };

    // ✅ Function to update examId and switch to Edit Exam Tab
    const handleEditExam = (examId) => {
        setSelectedExamId(examId);
        setActiveTab("edit-exam");
    };

    const menuItems = [
        { id: 'dashboard', label: 'Dashboard', icon: '🏠', component: <DashboardContent courses={courses} allPayments={allPayments} totalEarnings={totalEarnings} totalStudents={totalStudents} allUserData={allUserData} /> },
        { id: 'courses', label: 'Courses', icon: '📚', component: <CourseControl onEditCourse={handleEditCourse} allPayments={allPayments} /> }, 
        { id: 'course-upload', label: 'Add New Course', icon: '➕', component: <UploadCourseForm /> },
        { id: 'exams', label: 'Exams', icon: '📝', component: <ExamControl courses={courses} onEditExam={handleEditExam} /> }, // ✅ Pass handleEditExam
        { id: 'exam-upload', label: 'Add New Exam', icon: '➕', component: <UploadExamForm courses={courses} /> },
        { id: 'you-students', label: 'Your All Students', icon: '👨‍🎓', component: <ManageStudents allStudentsData={allUserData} allPayments={allPayments} /> }
    ];

    const renderActiveComponent = () => {
        if (activeTab === "edit-course" && selectedCourseId) {
            return <EditCourseForm courseId={selectedCourseId} />;
        }
        if (activeTab === "edit-exam" && selectedExamId) {
            return <EditExamForm courses={courses} examId={selectedExamId} />; // ✅ Show Edit Exam form
        }

        const activeItem = menuItems.find(item => item.id === activeTab);
        return activeItem?.component || <DashboardContent />;
    };

    return (
        <div className="teacher-dashboard">
            <aside className={`teacher-sidebar ${!isSidebarOpen ? 'teacher-collapsed' : ''}`}>
                <div className="teacher-logo-section">
                    <div className="teacher-logo">
                        <span> Instructor Dashboard</span>

                    </div>
                    <button className="teacher-toggle-btn" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                        {isSidebarOpen ? '←' : '→'}
                    </button>
                </div>
                <nav className="teacher-nav-links">
                    {menuItems.map((item) => (
                        <a
                            key={item.id}
                            href="#"
                            className={`teacher-nav-item ${activeTab === item.id ? 'teacher-active' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                setActiveTab(item.id);
                            }}
                        >
                            {item.icon}
                            <span>{item.label}</span>
                        </a>
                    ))}

                    {/* ✅ Show Edit Course only when a course is selected */}
                    {selectedCourseId && (
                        <a
                            href="#"
                            className={`teacher-nav-item ${activeTab === "edit-course" ? 'teacher-active' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                setActiveTab("edit-course");
                            }}
                        >
                            ✏️ <span>Edit Course</span>
                        </a>
                    )}

                    {/* ✅ Show Edit Exam only when an exam is selected */}
                    {selectedExamId && (
                        <a
                            href="#"
                            className={`teacher-nav-item ${activeTab === "edit-exam" ? 'teacher-active' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                setActiveTab("edit-exam");
                            }}
                        >
                            ✏️ <span>Edit Exam</span>
                        </a>
                    )}
                </nav>
            </aside>

            <main className="teacher-main-content">
                <header className="teacher-header">
                    <h2 className="teacher-header-title">
                        {activeTab === "edit-course" ? "Edit Course" :
                            activeTab === "edit-exam" ? "Edit Exam" :
                                menuItems.find(item => item.id === activeTab)?.label}
                    </h2>
                </header>

                <div className="teacher-content">
                    {renderActiveComponent()}
                </div>
            </main>
        </div>
    );
};

export default TeacherDashboard;
