import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import AuthContext from "../../context/AuthContext";
import config from "../../config";
import ExamList from "./ExamList";

// Registering chart components
ChartJS.register(CategoryScale, BarElement, Title, Tooltip, Legend);

const getBarChartData = (group) => {
  return {
    labels: ["Total", "Passed", "Failed", "Incomplete"],
    datasets: [
      {
        label: group.exam.title,
        data: [
          group.totalAttempts,
          group.totalPassed,
          group.totalFailed,
          group.totalIncomplete,
        ],
        backgroundColor: ["#4CAF50", "#FF9800", "#F44336", "#FFEB3B"],
        borderColor: ["#388E3C", "#F57C00", "#D32F2F", "#FBC02D"],
        borderWidth: 1,
      },
    ],
  };
};


const ExamControl = ({ courses, onEditExam }) => {
  const [examData, setExamData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { authTokens } = useContext(AuthContext);
  const token = authTokens?.access;

  useEffect(() => {
    const fetchExamAttempts = async () => {
      try {
        const response = await axios.get(
          `${config.apiUrl}exam-attempts-instructor/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
        );
        const rawData = response.data;

        // Group the data by exam ID and prepare dashboard stats
        const groupedData = groupByExam(rawData);
        setExamData(groupedData);
      } catch (error) {
        console.error("Error fetching exam attempts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchExamAttempts();
  }, []);

  const groupByExam = (data) => {
    const grouped = data.reduce((acc, attempt) => {
      const examId = attempt.exam.id;
      if (!acc[examId]) {
        acc[examId] = {
          exam: attempt.exam,
          attempts: [],
        };
      }
      acc[examId].attempts.push(attempt);
      return acc;
    }, {});

    return Object.values(grouped).map((group) => {
      const totalAttempts = group.attempts.length;
      const totalPassed = group.attempts.filter((a) => a.is_passed).length;
      const totalFailed = group.attempts.filter(
        (a) => a.end_time && !a.is_passed
      ).length;
      const totalIncomplete = group.attempts.filter((a) => !a.end_time).length;

      return {
        ...group,
        totalAttempts,
        totalPassed,
        totalFailed,
        totalIncomplete,
      };
    });
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="dashboard-container">
      <ExamList courses={courses} onEditExam={onEditExam} />
      <h1 className="text-3xl font-bold  my-8">
        Exam Statistics
      </h1>
      <div className="flex flex-row gap-0 flex-wrap justify-between">
      {examData.map((examGroup) => (

          <ExamCard key={examGroup.exam.id} group={examGroup} />
        ))}
        </div>
    </div>
  );
};

const ExamCard = ({ group }) => {
  const barChartData = getBarChartData(group);

  return (
    <div className="exam-card">
      <h2 className="exam-title">{group.exam.title}</h2>
      <p className="exam-description">{group.exam.description}</p>

      {/* Bar Chart Representation */}
      <div className="exam-chart">
        <Bar data={barChartData} options={{ responsive: true, maintainAspectRatio: false }} />
      </div>

      <div className="exam-stats flex  gap-2 p-2 items-center"> 
        <p className="bg-blue-500 p-1 rounded-ss-xl"> Attempts: <strong>{group.totalAttempts} </strong> </p>
        <p className="bg-green-500 p-1 rounded-ss-xl">Passed:<strong>{group.totalPassed} </strong> </p>
        <p className="bg-red-500 p-1 rounded-ss-xl">Failed: <strong>{group.totalFailed}</strong> </p>
        <p className="bg-yellow-500 p-1 rounded-ss-xl">Incomplete:  <strong> {group.totalIncomplete}</strong> </p>
      </div>
    </div>
  );
};

export default ExamControl;
