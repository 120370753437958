import { useState } from "react";
import './HomeCss/newsletter.css';
import { Button } from "@mui/material";

const NewsletterSubscription = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSubscribe = () => {
        if (!email) {
            setMessage("⚠️ Please enter an email address.");
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setMessage("❌ Please enter a valid email.");
            return;
        }

        setMessage("✅ Thank you for subscribing!");
        setEmail(""); // Clear the input after submission
    };

    return (
        <div className="newsletter">
            <h2 className="newsletter-heading">📩 Subscribe to Our Newsletter</h2>
            <p className="newsletter-subheading">
                Stay updated with the latest courses, offers, and insights.
            </p>

            <div className="subscription-box">
                <input
                    type="email"
                    className="subscription-input"
                    placeholder="Enter your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Button className="subscribe-btn" onClick={handleSubscribe}>
                    🚀 Subscribe Now
                </Button>
                {message && <p className="subscription-message">{message}</p>}
            </div>
        </div>
    );
};

export default NewsletterSubscription;
