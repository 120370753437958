import React from 'react'

const DashboardContent = ({courses,allPayments,totalEarnings,totalStudents, allUserData}) => {
  return (
    <div>
        <div className="my-8">
            <div className='flex flex-row gap-4 justify-center text-center '>

                <div className="">
                    <div className="teacher-card">
                        <h3 className="teacher-card-title">Your All Courses</h3>
                        <h2 className='text-xl font-semibold'>{courses.length}</h2>
                    </div>

                </div>
                <div className="">
                    <div className="teacher-card">
                        <h3 className="teacher-card-title">Total Course Sold</h3>
                        <h2 className='text-xl font-semibold'>{allPayments.length}</h2>
                    </div>

                </div>
                <div className=" shadow-lg">
                    <div className="teacher-card">
                        <h3 className="teacher-card-title">Total Earnings</h3>
                        <h2 className='text-xl font-semibold'>{totalEarnings} Tk</h2>
                    </div>

                </div>
                <div className=" shadow-lg drop-shadow-lg">
                    <div className="teacher-card">
                        <h3 className="teacher-card-title">Total Students</h3>
                        <h2 className='text-xl font-semibold'>{totalStudents} </h2>
                    </div>
                </div>

            </div>
            <div className="teacher-card my-8">
                <h3 className="teacher-card-title">Payment Details</h3>
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Transaction ID</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Course Title</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Date</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Amount</th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Student</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {allPayments.map((payment) => {
                                const course = courses.find(course => course.courseId === payment.courseId);
                                const currentUser = allUserData.find(user => user.user === payment.userId);
                                return (
                                    <tr key={payment.paymentId}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{payment.transactionId}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{course?.courseTitle}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(payment.paymentDate).toLocaleString()}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{payment.payment}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{currentUser?.firstName}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DashboardContent