import React from "react";
import FloatingButtons from "./routes/Home/FloatingButtons";
import OfferAlertModal from "./components/OfferAlertModal";

const Layout = ({ children }) => {
  return (
    <div>
      <OfferAlertModal/> {/* Offer alert modal appears on every page */}
      {children} {/* Renders the main page content */}
      <FloatingButtons/> {/* Floating buttons appear on every page */}
      
    </div>
  );
};

export default Layout;
